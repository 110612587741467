import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Row, Col, Form, Button, Image } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { MdOutlineBlock } from "react-icons/md";
import {
  useUploadImageMutation,
  useUpdateUserMutation,
  useGetUserDetailsQuery,
  useDeleteUserMutation,
  useUpdateUserBlockStatusMutation,
} from "../../redux";
import {
  FormContainer,
  Loader,
  Message,
  Asterisk,
  EditBtn,
} from "../../components";
import { imageCheckAndUpload } from "../../functions";
import { EmailValidator, NameValidator } from "../../validators";

export const UserEditPage = () => {
  const { userId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isUpdatable, setIsUpdatable] = useState(true);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [image, setImage] = useState("");
  const [isBlocked, setIsBlocked] = useState(false);
  const [isAdmin, setIsAdmin] = useState("false");
  const [imageError, setImageError] = useState(null);

  const {
    data: user,
    isLoading,
    error,
    refetch,
  } = useGetUserDetailsQuery(userId);

  const [updateUser, { isLoading: isUpdating }] = useUpdateUserMutation();
  const [uploadImage, { isLoading: isLoadingUpload }] =
    useUploadImageMutation();
  const [deleteUser, { isDeleting }] = useDeleteUserMutation();
  const [updateUserBlockStatus, { isLoading: loadingBlockStatus }] =
    useUpdateUserBlockStatusMutation();

  const submitHandler = async (e) => {
    e.preventDefault();
    if (!NameValidator(name)) {
      toast.error(`${t("PleaseEnterValidName")}`);
      return;
    }

    if (!EmailValidator(email)) {
      toast.error(`${t("PleaseEnterValidName")}`);
      return;
    }
    const imagePath = await imageCheckAndUpload({
      image,
      setImage,
      setImageError,
      uploadImage,
    });

    const updatedItem = {
      userId,
      name,
      image: imagePath,
      email,
      isAdmin,
    };

    try {
      const res = await updateUser(updatedItem).unwrap();
      if (res.error) {
        toast.error(res.error);
      } else {
        toast.success("User updated");
      }
      setIsUpdatable(true);
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  const handleUpdateUserBlockStatus = async () => {
    try {
      await updateUserBlockStatus(userId).unwrap();
      refetch();
      toast.success(`${t("UserBlockStatusUpdated")}`);
    } catch (err) {
      toast.error(err?.data?.message || err.message);
    }
  };

  const deleteHandler = async (e) => {
    e.preventDefault();
    if (window.confirm(`${t("AreYouSure")}`)) {
      try {
        await deleteUser(userId).unwrap();
        navigate("/admin/users");
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
    }
  };

  useEffect(() => {
    if (user) {
      const propOrString = (value, orValue = "") => {
        if (value === "isAdmin") {
          return user[value].toString();
        }
        return !!user?.[value] ? user[value] : orValue;
      };

      setName(propOrString("name"));
      setEmail(propOrString("email", ""));
      setImage(propOrString("image", ""));
      setIsAdmin(propOrString("isAdmin", "false"));
      setIsBlocked(propOrString("isBlocked"));
    }
  }, [user]);

  return (
    <>
      <Link to="/admin/users" className="btn btn-light my-3">
        {t("goBack")}
      </Link>
      <FormContainer>
        <h1>
          {t("UserProfile")}
          {!!isUpdatable && <EditBtn setIsUpdatable={setIsUpdatable} />}
        </h1>
        {isUpdating ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">
            {error?.data?.message || error.error}
          </Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <Form.Group controlId="name" className="my-3">
              <Form.Label>
                {t("Name")} <Asterisk />
              </Form.Label>
              <Form.Control
                readOnly={isUpdatable}
                type="name"
                placeholder={t("EnterName")}
                value={name}
                maxLength={100}
                minLength={3}
                onChange={(e) => setName(e.target.value)}
                required
                isInvalid={!(NameValidator(name) || name === "")}
              ></Form.Control>
              <Form.Control.Feedback type="invalid">
                {t("PleaseUseOnlyLettersAndDigits")}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="contactMail" className="my-3">
              <Form.Label>
                {t("Email")} <Asterisk />
              </Form.Label>
              <Form.Control
                type="email"
                readOnly={isUpdatable}
                maxLength={100}
                placeholder={t("EnterContactMail")}
                value={email}
                required
                onChange={(e) => setEmail(e.target.value)}
                isInvalid={!(EmailValidator(email) || email === "")}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="image">
              <Form.Label>{t("Image")}</Form.Label>
              <Col style={{ display: "flex", justifyContent: "center" }}>
                {image && (
                  <Image
                    className="mb-3"
                    src={
                      typeof image === "object" && image !== null
                        ? URL.createObjectURL(image)
                        : image
                    }
                    alt="Preview"
                    thumbnail
                    style={{
                      height: "200px",
                      width: "200px",
                      borderRadius: "50%",
                      objectFit: "cover",
                    }}
                  />
                )}
              </Col>
              <Form.Control
                label={t("ChooseFile")}
                type="file"
                accept="image/*"
                disabled={isLoadingUpload || isUpdatable}
                onChange={(e) => setImage(e.target.files[0])}
              />
              {imageError && (
                <Form.Text className="text-danger">{imageError}</Form.Text>
              )}
            </Form.Group>

            <Form.Group controlId="isOnTheGo" className="my-3">
              <Form.Label>
                {t("Admin")} <Asterisk />
              </Form.Label>
              <Form.Control
                as="select"
                disabled={isUpdatable}
                placeholder={t("SpecifyIfUserIsAdmin")}
                onChange={(e) => setIsAdmin(e.target.value)}
                value={isAdmin}
              >
                <option value="true">{t("Yes")}</option>
                <option value="false">{t("No")}</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="error" className="my-3">
              {error && (
                <Message variant="danger">
                  {error?.data?.message || error.error}
                </Message>
              )}
            </Form.Group>
            {!isUpdatable && (
              <Row className="d-flex justify-content-between">
                <Col className="d-flex justify-content-center">
                  <Button
                    type="submit"
                    variant="info"
                    className="mt-2 px-4 text-light"
                    disabled={isLoading}
                  >
                    {t("Update")}
                  </Button>
                </Col>
                <Col className="d-flex justify-content-center">
                  <Button
                    variant="danger"
                    className="mt-2 px-4 text-light"
                    disabled={isUpdating || isDeleting}
                    onClick={() => setIsUpdatable(true)}
                  >
                    {t("Cancel")}
                  </Button>
                </Col>
              </Row>
            )}
            {isUpdatable && (
              <Row
                className="d-flex justify-content-center p-0"
                style={{ lineHeight: "0.7", marginInline: "0.05rem" }}
              >
                {loadingBlockStatus && <Loader />}
                <Button
                  type="button"
                  variant="light"
                  className="btn btn-block d-flex justify-content-center align-items-center"
                  onClick={handleUpdateUserBlockStatus}
                >
                  <MdOutlineBlock
                    className="mx-1"
                    style={{ color: isBlocked ? "var(--bs-cyan)" : "red" }}
                  />
                  {isBlocked ? t("UnblockUserAccount") : t("BlockUserAccount")}
                </Button>
                <Button
                  variant="danger"
                  className="mt-2 p-0 text-light"
                  disabled={isUpdating || isDeleting}
                  onClick={deleteHandler}
                >
                  {t("DeleteUser")}
                </Button>
              </Row>
            )}

            {isLoading && <Loader />}
          </Form>
        )}
      </FormContainer>
    </>
  );
};
