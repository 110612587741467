import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "./slices/api/api.slice.js";
import cartSliceReducer from "./slices/cart.slice.js";
import userReducer from "./slices/user.slice.js";
import itemReducer from "./slices/item.slice.js";

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    cart: cartSliceReducer,
    login: userReducer,
    item: itemReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
  devTools: true,
});
