import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Form, Image } from "react-bootstrap";

export const ImagePicker = ({ isUpdatable, setImage }) => {
  const { t } = useTranslation();
  const [previewImage, setPreviewImage] = useState(null);
  const [imageError, setImageError] = useState(null);

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (!!file) {
      if (file?.size < 1024 * 1024) {
        setImageError(null);
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreviewImage(reader.result);
          setImage(reader.result);
        };
        reader.readAsDataURL(file);
      } else {
        e.target.value = "";
        setPreviewImage(null);
        setImage(null);
        setImageError(`${t("ImageSizeShouldBeLessThan10MB")}`);
      }
    } else {
      e.target.value = "";
      setPreviewImage(null);
      setImage(null);
      setImageError(null);
    }
  };

  return (
    <Form.Group controlId="formFile">
      <Form.Label>{t("ProfilePicture")}</Form.Label>
      <Form.Text></Form.Text>
      <Col style={{ display: "flex", justifyContent: "center" }}>
        {previewImage && (
          <Image
            className="mb-3"
            src={previewImage}
            alt="Preview"
            thumbnail
            style={{
              height: "200px",
              width: "200px",
              borderRadius: "50%",
              objectFit: "cover",
            }}
          />
        )}
      </Col>
      <Form.Control
        readOnly={isUpdatable}
        type="file"
        accept="image/*"
        onChange={handleImageChange}
      />
      {imageError && (
        <Form.Text className="text-danger">{imageError}</Form.Text>
      )}
    </Form.Group>
  );
};
