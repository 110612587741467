import { ListGroup, Row, Col } from "react-bootstrap";
import { FaCalendarAlt } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const WorkingTimetable = ({ timetable }) => {
  const { t } = useTranslation();

  return (
    <ListGroup.Item style={{ borderRadius: "10px" }} className="pt-0">
      <Row
        className="p-1 fs-3"
        style={{
          backgroundColor: "var(--bs-blue)",
          color: "var(--bs-white)",
          borderRadius: "0 0 10px 10px",
        }}
      >
        <FaCalendarAlt />
      </Row>
      {Object.keys(timetable).map((day) => (
        <Row key={day}>
          <Col>{t(day.charAt(0).toUpperCase() + day.slice(1))}:</Col>
          {timetable[day].closed ? (
            <Col className="text-center">{t("Closed")}</Col>
          ) : (
            <Col className="d-flex">
              <Col>{timetable[day].opening}</Col>-
              <Col>{timetable[day].closing}</Col>
            </Col>
          )}
        </Row>
      ))}
    </ListGroup.Item>
  );
};

export { WorkingTimetable };
