import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Loader, FormContainer } from "../components";
import { useRegisterMutation } from "../redux";
import {
  EmailValidator,
  NameValidator,
  PasswordValidator,
} from "../validators";

export const RegisterPage = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const navigate = useNavigate();

  const [register, { isLoading }] = useRegisterMutation();
  const { userInfo } = useSelector((state) => state.login);
  const { search } = useLocation();
  const { t } = useTranslation();
  const sp = new URLSearchParams(search);
  const redirect = sp.get("redirect") || "/";

  const submitHandler = async (e) => {
    e.preventDefault();
    if (!NameValidator(name)) {
      toast.error(`${t("PleaseEnterValidName")}`);
      return;
    }
    if (!EmailValidator(email)) {
      toast.error(`${t("PleaseEnterValidContactMail")}`);
      return;
    }
    if (!PasswordValidator(password)) {
      toast.error(`${t("PleaseEnterValidPassword")}`);
      return;
    } else {
      if (password !== confirmPassword) {
        toast.error(`${t("PasswordsDoNotMatch")}`);
        return;
      } else {
        try {
          const { message } = await register({
            name,
            email,
            password,
          }).unwrap();
          toast.success(message, {
            autoClose: 20000,
          });
          navigate(redirect);
        } catch (err) {
          toast.error(err?.data?.message || err.error);
        }
      }
    }
  };

  useEffect(() => {
    if (userInfo) {
      navigate(redirect);
    }
  }, [userInfo, redirect, navigate]);

  return (
    <FormContainer>
      <h1>{t("SignUp")}</h1>

      <Form onSubmit={submitHandler}>
        <Form.Group controlId="name" className="my-3">
          <Form.Label>{t("Name")}</Form.Label>
          <Form.Control
            type="name"
            placeholder={t("EnterName")}
            value={name}
            maxLength={100}
            onChange={(e) => setName(e.target.value)}
            required
            isInvalid={!(NameValidator(name) || name === "")}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId="email" className="my-3">
          <Form.Label>{t("Email")}</Form.Label>
          <Form.Control
            type="email"
            placeholder={t("EnterEmail")}
            maxLength={100}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            isInvalid={!(EmailValidator(email) || email === "")}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId="password" className="my-3">
          <Form.Label>{t("Password")}</Form.Label>
          <Form.Control
            type="password"
            placeholder={t("EnterPassword")}
            value={password}
            maxLength={100}
            onChange={(e) => setPassword(e.target.value)}
            required
            isInvalid={!(PasswordValidator(password) || password === "")}
          ></Form.Control>
          <Form.Control.Feedback type="invalid">
            {t("PasswordRecomendations")}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="confirmPassword" className="my-3">
          <Form.Label>{t("ConfirmPassword")}</Form.Label>
          <Form.Control
            type="password"
            placeholder={t("EnterConfirmPassword")}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          ></Form.Control>
        </Form.Group>

        <Button
          type="submit"
          variant="primary"
          className="mt-2"
          disabled={isLoading}
        >
          {t("Register")}
        </Button>
        {isLoading && <Loader />}
      </Form>

      <Row className="py-3">
        <Col>
          {t("AlreadyHaveAccount")}
          <Link to={redirect ? `/login?redirect=${redirect}` : "/login"}>
            {t("Login")}
          </Link>
        </Col>
      </Row>
    </FormContainer>
  );
};
