import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, ListGroup, Button, Card } from "react-bootstrap";
import { FaTrash, FaPhone, FaMailBulk, FaMapMarkedAlt } from "react-icons/fa";
import { Message } from "../components";
import { removeFromCart } from "../redux";

export const CartPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { cartItems } = useSelector((state) => state.cart);

  const removeFromCartHandler = async (id) => {
    dispatch(removeFromCart(id));
  };

  return (
    <Row>
      <Col>
        <h1 style={{ marginBottom: "20px" }}>{t("Saved")}</h1>
        {cartItems.length === 0 ? (
          <Message>
            {t("YourCartIsEmpty")}
            <Link to="/">{t("goBack")}</Link>
          </Message>
        ) : (
          <ListGroup variant="flush">
            {cartItems.map((item) => {
              const {
                _id = "",
                image = "",
                name = "",
                coordinates: { lat = 0, lng = 0 } = {},
                address = "",
                contactPhone1 = "",
                contactPhone2 = "",
                contactMail = "",
              } = item || {};

              return (
                <ListGroup.Item key={_id}>
                  <Card className="col-12 col-md-2">
                    <Card.Title as="div" className="sto-title">
                      <Col className="d-flex align-items-center flex-nowrap justify-content-between">
                        <Link to={`/service/${_id}`}>
                          <Col className="text-center">
                            <h5>{name}</h5>
                          </Col>
                        </Link>
                        <Col className="text-center">
                          <Button
                            type="button"
                            variant="light"
                            onClick={() => removeFromCartHandler(item._id)}
                          >
                            <FaTrash />
                          </Button>
                        </Col>
                      </Col>
                    </Card.Title>
                    <Link to={`/service/${_id}`}>
                      <Card.Img
                        src={image}
                        variant="top"
                        style={{ objectFit: "scale-down" }}
                      />
                    </Link>
                    <Card.Body>
                      <Card.Text
                        as="div"
                        style={{ fontSize: "0.8rem" }}
                        className="text-center"
                      >
                        <Col
                          onClick={() =>
                            window.open(
                              `https://www.google.com/maps/dir//${lat},${lng}`,
                              "_blank"
                            )
                          }
                        >
                          <Col>
                            <span className="p-1 fs-3">
                              <FaMapMarkedAlt />
                            </span>
                            {address}
                          </Col>
                          <Row
                            style={{
                              fontSize: "10px",
                              marginLeft: "0.3rem",
                            }}
                          >
                            ({t("clickToOpenOnMap")})
                          </Row>
                        </Col>

                        {!!contactPhone1 && (
                          <Col
                            className="btn-link"
                            onClick={() =>
                              window.open(`tel:${contactPhone1}`, "_self")
                            }
                          >
                            <span className="p-1">
                              <FaPhone />
                            </span>
                            {contactPhone1}
                          </Col>
                        )}
                        {!!contactPhone2 && (
                          <Col
                            className="btn-link"
                            onClick={() =>
                              window.open(`tel:${contactPhone2}`, "_self")
                            }
                          >
                            <span className="p-1">
                              <FaPhone />
                            </span>
                            {contactPhone2}
                          </Col>
                        )}
                        <Col>
                          <Button
                            onClick={() =>
                              window.open(
                                `https://mail.google.com/mail/?view=cm&fs=1&to=${contactMail}`,
                                "_blank"
                              )
                            }
                            className="btn-mail"
                          >
                            <span className="p-1">
                              <FaMailBulk />
                            </span>
                            {contactMail}
                          </Button>
                        </Col>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </ListGroup.Item>
              );
            })}
          </ListGroup>
        )}
      </Col>
    </Row>
  );
};
