import { useParams, useNavigate } from "react-router-dom";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  Row,
  Col,
  Image,
  ListGroup,
  Card,
  Button,
  ButtonGroup,
  Form,
} from "react-bootstrap";
import { Rating, Loader, Message, WorkingTimetable, Meta } from "../components";
import {
  FaMailBulk,
  FaPhone,
  FaMapMarkedAlt,
  FaRegBookmark,
  FaInstagram,
  FaFacebook,
} from "react-icons/fa";
import { RxCross1 } from "react-icons/rx";
import { TbWorld } from "react-icons/tb";
import { GiCheckMark, GiRoad } from "react-icons/gi";
import { useTranslation } from "react-i18next";

import {
  useGetStoDetailsQuery,
  addToCart,
  useCreateReviewMutation,
} from "../redux";

export const ItemPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [qty] = useState(1);
  const [showContacts, setShowContacts] = useState(false);
  const [comment, setComment] = useState("");
  const { t } = useTranslation();
  const { id: stoId } = useParams();
  const {
    data: sto,
    isLoading,
    isError,
    refetch,
  } = useGetStoDetailsQuery(stoId);
  const [createReview, { isLoading: isLoadingReview }] =
    useCreateReviewMutation();
  const { userInfo } = useSelector((state) => state.login);

  const {
    image = "",
    name = "",
    numReviews = 0,
    description = "",
    timetable = {},
    coordinates: { lat = 0, lng = 0 } = {},
    address = "",
    contactPhone1 = "",
    contactPhone2 = "",
    website = "",
    instagram = "",
    facebook = "",
    contactMail = "",
    isOnTheGo = "",
    rating = 0,
  } = sto || {};

  const addToCartHandler = () => {
    dispatch(addToCart({ ...sto, qty }));
    navigate("/cart");
  };

  const [qualityRating, setQualityRating] = useState(0);
  const [speedRating, setSpeedRating] = useState(0);
  const [priceRating, setPriceRating] = useState(0);

  const submitHandler = async (e) => {
    e.preventDefault();

    try {
      await createReview({
        stoId,
        rating: {
          quality: qualityRating,
          speed: speedRating,
          price: priceRating,
        },
        comment,
      }).unwrap();
      refetch();
      toast.success(`${t("ReviewSubmitted")}`);
      setQualityRating(0);
      setSpeedRating(0);
      setPriceRating(0);
      setComment("");
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  return (
    <>
      <Link className="btn btn-light my-3" to="/services">
        {t("goBack")}
      </Link>

      {isLoading ? (
        <Loader />
      ) : isError ? (
        <Message variant="danger">
          {isError?.data?.message || isError.error}
        </Message>
      ) : (
        <>
          <Meta title={name} />
          <Row>
            <Col md={5}>
              <Image src={image} alt={name} fluid />
            </Col>
            <Col md={4}>
              <ListGroup variant="flush">
                <ListGroup.Item
                  style={{ borderBottom: "rgb(225 225 225) solid 0.5px" }}
                >
                  <Row>
                    <Col style={{ maxWidth: "max-content" }}>
                      <h3 style={{ lineHeight: 1.3 }}>{name}</h3>
                    </Col>
                    <Col>
                      <Button
                        size="lg"
                        style={{
                          padding: 0,
                          border: 0,
                          color: "unset",
                          fontSize: "1.5rem",
                          lineHeight: 1.3,
                          backgroundColor: "transparent",
                          boxShadow: "none",
                        }}
                        onClick={addToCartHandler}
                      >
                        <FaRegBookmark />
                      </Button>
                    </Col>
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item
                  className="p-2"
                  style={{
                    fontSize: "0.8rem",
                    borderBottom: "#c3bfbf solid 0.5px",
                  }}
                >
                  <span className="m-1">
                    <GiRoad />
                  </span>
                  {t("onTheRoadService")}
                  <span className="m-1">
                    {isOnTheGo ? <GiCheckMark /> : <RxCross1 />}
                  </span>
                </ListGroup.Item>

                <ListGroup.Item>
                  <Rating
                    value={rating}
                    text={`${numReviews} ${t("reviews")}`}
                  />
                </ListGroup.Item>

                <ListGroup.Item>
                  <Col
                    onClick={() =>
                      window.open(
                        `https://www.google.com/maps/dir//${lat},${lng}`,
                        "_blank"
                      )
                    }
                  >
                    <Col>
                      <span className="p-1 fs-3">
                        <FaMapMarkedAlt />
                      </span>
                      {address}
                    </Col>
                    <Row style={{ fontSize: "10px", marginLeft: "0.3rem" }}>
                      ({t("clickToOpenOnMap")})
                    </Row>
                  </Col>
                </ListGroup.Item>

                {!!description && (
                  <ListGroup.Item>
                    {t("Description")}: {description}
                  </ListGroup.Item>
                )}
              </ListGroup>
            </Col>
            <Col md={3}>
              <Card>
                <ListGroup variant="flush">
                  <ListGroup.Item className="p-2 pb-3">
                    <Col>
                      <Col className="d-flex flex-column align-items-center">
                        {t("Timetable")}
                        <WorkingTimetable timetable={timetable} />
                      </Col>
                    </Col>
                  </ListGroup.Item>
                  {showContacts && (
                    <ListGroup.Item>
                      <Col>
                        {!!contactPhone1 && (
                          <Col
                            className="btn-link"
                            onClick={() =>
                              window.open(`tel:${contactPhone1}`, "_self")
                            }
                          >
                            <span className="p-1">
                              <FaPhone />
                            </span>
                            {contactPhone1}
                          </Col>
                        )}
                        {!!contactPhone2 && (
                          <Col
                            className="btn-link"
                            onClick={() =>
                              window.open(`tel:${contactPhone2}`, "_self")
                            }
                          >
                            <span className="p-1">
                              <FaPhone />
                            </span>
                            {contactPhone2}
                          </Col>
                        )}
                        <Row className="rating-text">
                          {!!website && (
                            <Row>
                              <Row>{t("Website")}:</Row>
                              <ButtonGroup
                                onClick={() => window.open(website, "_blank")}
                                className="btn-mail d-flex align-items-center"
                              >
                                <span className="p-1">
                                  <TbWorld />
                                </span>
                                {website}
                              </ButtonGroup>
                            </Row>
                          )}

                          {!!facebook && (
                            <Row>
                              <Row>{t("Facebook")}:</Row>
                              <ButtonGroup
                                onClick={() => window.open(facebook, "_blank")}
                                className="btn-mail d-flex align-items-center"
                              >
                                <span className="p-1">
                                  <FaFacebook />
                                </span>
                                {facebook}
                              </ButtonGroup>
                            </Row>
                          )}

                          {!!instagram && (
                            <Row>
                              <Row>{t("Instagram")}:</Row>
                              <ButtonGroup
                                onClick={() => window.open(instagram, "_blank")}
                                className="btn-mail d-flex align-items-center"
                              >
                                <span className="p-1">
                                  <FaInstagram />
                                </span>
                                {instagram}
                              </ButtonGroup>
                            </Row>
                          )}

                          <Row>{t("Email")}:</Row>
                          <Button
                            onClick={() =>
                              window.open(
                                `https://mail.google.com/mail/?view=cm&fs=1&to=${contactMail}`,
                                "_blank"
                              )
                            }
                            className="btn-mail"
                          >
                            <span className="p-1">
                              <FaMailBulk />
                            </span>
                            {contactMail}
                          </Button>
                        </Row>
                      </Col>
                    </ListGroup.Item>
                  )}
                  <ListGroup.Item className="d-flex justify-content-center">
                    <Button
                      className="btn-block"
                      type="button"
                      onClick={() => setShowContacts(!showContacts)}
                    >
                      {showContacts ? t("HideContacts") : t("ShowContacts")}
                    </Button>
                  </ListGroup.Item>
                </ListGroup>
              </Card>
            </Col>
          </Row>
          <Row className="review">
            <Col md={6}>
              <h2>{t("Reviews")}</h2>
              {sto.reviews.length === 0 && <Message>{t("NoReviews")}</Message>}
              <ListGroup.Item variant="flush">
                {sto.reviews.map((review) => (
                  <ListGroup.Item key={review._id}>
                    <strong>{review.name}</strong>
                    <Rating value={review.rating.avg} />
                    <p>{review.createdAt.substring(0, 10)}</p>
                    <p>{review.comment}</p>
                  </ListGroup.Item>
                ))}
                <ListGroup.Item>
                  <h2 style={{ color: "inherit", fontWeight: "500" }}>
                    {t("WriteCustumerReview")}
                  </h2>
                  {userInfo ? (
                    <Form onSubmit={submitHandler}>
                      <Col className="my-3 lead mb-2">
                        <Row
                          style={{
                            color: "var(--bs-heading-color, inherit)",
                            fontWeight: "500",
                          }}
                        >
                          Rating
                        </Row>
                      </Col>
                      <Col className="d-flex justify-content-between">
                        <Form.Group controlId="qualityRating" className="w-32">
                          <Form.Label>
                            {t("Quality")}
                            <Rating value={qualityRating} gray={true} />
                          </Form.Label>
                          <Form.Control
                            as="select"
                            required
                            value={qualityRating}
                            onChange={(e) =>
                              setQualityRating(Number(e.target.value))
                            }
                          >
                            <option value="">{t("Select")}</option>
                            <option value="1">{t("1Poor")}</option>
                            <option value="2">{t("2Fair")}</option>
                            <option value="3">{t("3Good")}</option>
                            <option value="4">{t("4VeryGood")}</option>
                            <option value="5">{t("5Excellent")}</option>
                          </Form.Control>
                        </Form.Group>

                        <Form.Group controlId="speedRating" className="w-32">
                          <Form.Label>
                            {t("Speed")}{" "}
                            <Rating value={speedRating} gray={true} />
                          </Form.Label>
                          <Form.Control
                            as="select"
                            required
                            value={speedRating}
                            onChange={(e) =>
                              setSpeedRating(Number(e.target.value))
                            }
                          >
                            <option value="">{t("Select")}</option>
                            <option value="1">{t("1Poor")}</option>
                            <option value="2">{t("2Fair")}</option>
                            <option value="3">{t("3Good")}</option>
                            <option value="4">{t("4VeryGood")}</option>
                            <option value="5">{t("5Excellent")}</option>
                          </Form.Control>
                        </Form.Group>

                        <Form.Group controlId="priceRating" className="w-32">
                          <Form.Label>
                            {t("Price")}{" "}
                            <Rating value={priceRating} gray={true} />
                          </Form.Label>
                          <Form.Control
                            as="select"
                            required
                            value={priceRating}
                            onChange={(e) =>
                              setPriceRating(Number(e.target.value))
                            }
                          >
                            <option value="">{t("Select")}</option>
                            <option value="1">{t("1Poor")}</option>
                            <option value="2">{t("2Fair")}</option>
                            <option value="3">{t("3Good")}</option>
                            <option value="4">{t("4VeryGood")}</option>
                            <option value="5">{t("5Excellent")}</option>
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Form.Group controlId="comment" className="my-2">
                        <Form.Label>{t("Comment")}</Form.Label>
                        <Form.Control
                          maxLength={600}
                          style={{ resize: "none" }}
                          as="textarea"
                          row="2"
                          value={comment}
                          onChange={(e) => setComment(e.target.value)}
                        ></Form.Control>
                      </Form.Group>

                      <Button
                        disabled={isLoadingReview}
                        type="submit"
                        variant="primary"
                      >
                        {t("Send")}
                      </Button>
                      {isLoadingReview && <Loader />}
                    </Form>
                  ) : (
                    <Message>
                      {t("Please")} <Link to={"/login"}>{t("signIn")}</Link>
                      {t("toWriteReview")}
                    </Message>
                  )}
                </ListGroup.Item>
              </ListGroup.Item>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};
