export const calculateTaxPrice = (price) => {
  const taxRate = 0.2; // 20% taxes
  const taxPrice = Math.round(price * taxRate * 100) / 100;
  return taxPrice;
};

export const calculatePriceWithoutTax = (price) => {
  const taxRate = 0.2; // 20% taxes
  const taxPrice = Math.round((price - price * taxRate) * 100) / 100;
  return taxPrice;
};
