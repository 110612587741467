export const addPhoneSpace = (e) => {
  const inputPhoneNumber = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
  let formattedPhoneNumber = "";

  for (let i = 0; i < inputPhoneNumber.length; i++) {
    if (i === 3 || i === 6) {
      formattedPhoneNumber += " "; // Insert space after every three digits
    }
    formattedPhoneNumber += inputPhoneNumber[i];
  }

  return formattedPhoneNumber;
};
