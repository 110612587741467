import { Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Rating } from "./Rating";
import { RiTeamFill } from "react-icons/ri";
import { IoMdPerson } from "react-icons/io";

export const StoCard = ({ sto }) => {
  return (
    <Card className="my-3 p-3 rounded">
      <Link to={`/service/${sto._id}`}>
        <Card.Subtitle className="w-100 d-flex align-content-center justify-content-end align-items-center">
          {sto.role === "sto" ? (
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="tooltip-top">This is a CSS.</Tooltip>}
            >
              <span>
                <RiTeamFill />
              </span>
            </OverlayTrigger>
          ) : (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="tooltip-top">
                  This is an independent master.
                </Tooltip>
              }
            >
              <span>
                <IoMdPerson />
              </span>
            </OverlayTrigger>
          )}
        </Card.Subtitle>
        <Card.Img
          src={sto.image}
          variant="top"
          style={{ objectFit: "scale-down", height: "200px" }}
        />
      </Link>
      <Card.Body>
        <Link to={`/service/${sto._id}`}>
          <Card.Title as="div" className="sto-title">
            <strong>{sto.name}</strong>
          </Card.Title>
        </Link>
        <Card.Text as="div">
          <Rating value={sto.rating} text={`${sto.numReviews} reviews`} />
        </Card.Text>
      </Card.Body>
    </Card>
  );
};
