import { Row, Col, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSearchParams, useNavigate, Link } from "react-router-dom";
import { MdClear } from "react-icons/md";

import {
  StoCard,
  Loader,
  Message,
  Paginate,
  ItemsCarousel,
  Meta,
  LocationSelect,
  SortSelect,
} from "../components";
import { useGetItemsQuery, useGetTopStosQuery } from "../redux";

export const ItemsPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const keyword = searchParams.get("keyword");
  const filter = searchParams.get("filter");
  const page = searchParams.get("page");
  const sort = searchParams.get("sort") || "";
  const onthego = searchParams.get("onthego");
  const open = searchParams.get("open");
  const near = searchParams.get("near");

  const { data: topStos, isLoadingTop } = useGetTopStosQuery();
  const { data, isLoading, isError } = useGetItemsQuery({
    filter,
    keyword,
    onthego,
    page,
    sort,
    open,
    near,
  });

  const handleFilterChange = (newFilter) => {
    if (filter === newFilter) {
      searchParams.delete("filter");
      searchParams.delete("page");
    } else {
      searchParams.delete("page");
      searchParams.set("filter", newFilter);
    }

    navigate({
      pathname: "/services",
      search: searchParams.toString(),
    });
  };

  const handleOnTheGoClick = () => {
    if (onthego) {
      searchParams.delete("onthego");
      searchParams.delete("page");
    } else {
      searchParams.delete("page");
      searchParams.set("onthego", true);
    }

    navigate({
      pathname: "/services",
      search: searchParams.toString(),
    });
  };

  const handleIsOpenClick = () => {
    if (open) {
      searchParams.delete("open");
      searchParams.delete("page");
    } else {
      searchParams.delete("page");
      searchParams.set("open", true);
    }

    navigate({
      pathname: "/services",
      search: searchParams.toString(),
    });
  };

  return (
    <>
      {!keyword && !filter && !sort && !onthego && !open && !near ? (
        <ItemsCarousel items={topStos} isLoad={isLoadingTop} />
      ) : (
        <Link to="/services" className="btn btn-light mb-4">
          <MdClear /> {t("Filter")}
        </Link>
      )}
      {isLoading ? (
        <Loader />
      ) : isError ? (
        <Message variant="danger">
          {isError?.data?.message || isError.error}
        </Message>
      ) : (
        <>
          <Meta />
          <Col className="d-flex flex-row gap-2 flex-wrap">
            <Button
              variant={filter === "sto" ? "primary" : "light"}
              style={{
                padding: "0 12px",
                borderRadius: "20px",
                border: "1px solid ",
                height: "min-content",
                color: filter !== "sto" ? "var(--bs-nav-link-color)" : "",
                backgroundColor: filter !== "sto" ? "transparent" : "",
              }}
              onClick={() => handleFilterChange("sto")}
            >
              {t("CSS")}
            </Button>
            <Button
              variant={filter === "mechanic" ? "primary" : "light"}
              style={{
                padding: "0 12px",
                borderRadius: "20px",
                border: "1px solid ",
                height: "min-content",
                color: filter !== "mechanic" ? "var(--bs-nav-link-color)" : "",
                backgroundColor: filter !== "mechanic" ? "transparent" : "",
              }}
              onClick={() => handleFilterChange("mechanic")}
            >
              {t("Masters")}
            </Button>
            <Button
              variant={onthego === "true" ? "primary" : "light"}
              style={{
                padding: "0 12px",
                borderRadius: "20px",
                border: "1px solid ",
                height: "min-content",
                color: onthego !== "true" ? "var(--bs-nav-link-color)" : "",
                backgroundColor: onthego !== "true" ? "transparent" : "",
              }}
              onClick={handleOnTheGoClick}
            >
              {t("Onthego")}
            </Button>
            <Button
              variant={open === "true" ? "primary" : "light"}
              style={{
                padding: "0 12px",
                borderRadius: "20px",
                border: "1px solid ",
                height: "min-content",
                color: open !== "true" ? "var(--bs-nav-link-color)" : "",
                backgroundColor: open !== "true" ? "transparent" : "",
              }}
              onClick={handleIsOpenClick}
            >
              {t("Open now")}
            </Button>

            <SortSelect sort={sort} />
            <LocationSelect near={near} />
          </Col>

          <h3>{t("AvailableServices")}</h3>
          <Row>
            {data.items.map((item) => (
              <Col key={item._id} sm={12} md={6} lg={4} xl={3}>
                <StoCard sto={item} />
              </Col>
            ))}
          </Row>
          <Paginate pages={data.pages} page={data.page} />
        </>
      )}
    </>
  );
};
