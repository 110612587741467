const importAll = (requireContext) => {
  const translations = {};
  requireContext.keys().forEach((key) => {
    const filename = key.replace('./', '').replace('.json', '');
    translations[filename] = { translation: requireContext(key) };
  });
  return translations;
};

const translations = importAll(require.context('./locales', false, /\.json$/));

export default translations;
