import { ButtonGroup, Badge } from "react-bootstrap";
import { FaPencilAlt } from "react-icons/fa";

export const EditBtn = ({ setIsUpdatable }) => {
  return (
    <ButtonGroup
      style={{ fontSize: "1rem ", marginBottom:"20px" }}
      onClick={() => setIsUpdatable((prevState) => !prevState)}
    >
      <Badge pill className="m-2" style={{marginTop:"-10px", padding:"0.5rem"}}>
        <FaPencilAlt />
      </Badge>
    </ButtonGroup>
  );
};
