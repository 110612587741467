import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { FaPencilAlt } from "react-icons/fa";
import {
  Row,
  Col,
  Button,
  ListGroup,
  Image,
  Card,
  ButtonGroup,
  Form,
} from "react-bootstrap";
import {
  FaMailBulk,
  FaPhone,
  FaMapMarkedAlt,
  FaRegBookmark,
  FaInstagram,
  FaFacebook,
} from "react-icons/fa";
import { RxCross1 } from "react-icons/rx";
import { TbWorld } from "react-icons/tb";
import { GiCheckMark, GiRoad } from "react-icons/gi";
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { toast } from "react-toastify";
import { Loader, Rating, WorkingTimetable } from "../components";
import { stoPriceWithoutTax, stoTaxPrice, stoPrice } from "../constants";
import {
  addUserStos,
  clearStoForOrder,
  usePayForStoAddingMutation,
  useGetPaypalClientIdQuery,
} from "../redux";
import StoAvatar from "../assets/avatar/serviceDefaultAvatar.jpeg";

const PlaceOrderPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const [showContacts, setShowContacts] = useState(false);
  const [paymentMethod] = useState("PayPal");
  const { stoForOrder } = useSelector((state) => state.cart);
  const {
    data: paypal,
    isLoading: loadingPaypal,
    error: errorPaypal,
  } = useGetPaypalClientIdQuery();

  const [payForSto, { isLoading: isPaymentLoading }] =
    usePayForStoAddingMutation();
  const [{ isPending }, paypalDispatch] = usePayPalScriptReducer();

  useEffect(() => {
    if (!errorPaypal && !loadingPaypal && paypal.clientId) {
      const loadPaypalScript = async () => {
        paypalDispatch({
          type: "resetOptions",
          value: {
            "client-id": paypal.clientId,
            currency: "USD",
          },
        });
        paypalDispatch({ type: "setLoadingStatus", value: "pending" });
      };
      if (stoForOrder && !stoForOrder.ispaid) {
        if (!window.paypal) {
          loadPaypalScript();
        }
      }
    }
  }, [stoForOrder, paypal, errorPaypal, loadingPaypal, paypalDispatch]);

  useEffect(() => {
    if (JSON.stringify(stoForOrder) === "{}") {
      toast.error(`${t("PleaseFillInAllFieldsBeforeProceed")}`);
      navigate("/addservice");
    }
  }, [stoForOrder, navigate, t]);

  function onApprove(data, actions) {
    return actions.order.capture().then(async function (details) {
      try {
        const { sto } = await payForSto({
          stoId: stoForOrder._id,
          details: { ...details, paymentMethod },
        }).unwrap();
        toast.success(`${t("PaymentSuccessful")}`);

        if (sto) {
          dispatch(addUserStos(sto));
          navigate(`/service/${sto._id}`, () => {
            dispatch(clearStoForOrder());
          });
        }
      } catch (err) {
        toast.error(err?.data?.message || err.message);
      }
    });
  }

  function onError(err) {
    toast.error(err.message);
  }

  function createOrder(data, actions) {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: stoPrice,
          },
        },
      ],
    });
  }

  return (
    <Row>
      <Col md={9}>
        <Link className="btn btn-light my-3" to="/addservice">
          <span className="p-1">
            <FaPencilAlt />
          </span>
          {t("BackToEdit")}
        </Link>

        <ListGroup variant="flush">
          <ListGroup.Item>
            <h2>{t("OrderItemPreview")}</h2>
            <Row>
              <Col md={5}>
                <Image
                  src={stoForOrder.image || StoAvatar}
                  alt={stoForOrder.name}
                  fluid
                />
              </Col>
              <Col md={4}>
                <ListGroup variant="flush">
                  <ListGroup.Item
                    style={{ borderBottom: "rgb(225 225 225) solid 0.5px" }}
                  >
                    <Row>
                      <Col style={{ maxWidth: "max-content" }}>
                        <h3 style={{ lineHeight: 1.3 }}>{stoForOrder.name}</h3>
                      </Col>
                      <Col>
                        <Button
                          size="lg"
                          style={{
                            padding: 0,
                            border: 0,
                            color: "unset",
                            fontSize: "1.5rem",
                            lineHeight: 1.3,
                            backgroundColor: "transparent",
                            boxShadow: "none",
                          }}
                        >
                          <FaRegBookmark />
                        </Button>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item
                    className="p-2"
                    style={{
                      fontSize: "0.8rem",
                      borderBottom: "#c3bfbf solid 0.5px",
                    }}
                  >
                    <span className="m-1">
                      <GiRoad />
                    </span>
                    {t("onTheRoadService")}
                    <span className="m-1">
                      {stoForOrder.isOnTheGo ? <GiCheckMark /> : <RxCross1 />}
                    </span>
                  </ListGroup.Item>

                  <ListGroup.Item>
                    <Rating value={5} text={`100 ${t("reviews")}`} />
                  </ListGroup.Item>

                  <ListGroup.Item>
                    <Col
                      onClick={() =>
                        window.open(
                          `https://www.google.com/maps/dir//${stoForOrder.coordinates.lat},${stoForOrder.coordinates.lng}`,
                          "_blank"
                        )
                      }
                    >
                      <Col>
                        <span className="p-1 fs-3">
                          <FaMapMarkedAlt />
                        </span>
                        {stoForOrder.address}
                      </Col>
                      <Row style={{ fontSize: "10px", marginLeft: "0.3rem" }}>
                        ({t("clickToOpenOnMap")})
                      </Row>
                    </Col>
                  </ListGroup.Item>

                  {!!stoForOrder.description && (
                    <ListGroup.Item>
                      {t("Description")}: {stoForOrder.description}
                    </ListGroup.Item>
                  )}
                </ListGroup>
              </Col>
              <Col md={3}>
                <Card>
                  <ListGroup variant="flush">
                    <ListGroup.Item>
                      <Col>
                        <Col className="d-flex flex-column align-items-center">
                          {t("Timetable")}
                          <WorkingTimetable timetable={stoForOrder.timetable} />
                        </Col>
                      </Col>
                    </ListGroup.Item>
                    {showContacts && (
                      <ListGroup.Item>
                        <Col>
                          {!!stoForOrder.contactPhone1 && (
                            <Col
                              className="btn-link"
                              onClick={() =>
                                window.open(
                                  `tel:${stoForOrder.contactPhone1}`,
                                  "_self"
                                )
                              }
                            >
                              <span className="p-1">
                                <FaPhone />
                              </span>
                              {stoForOrder.contactPhone1}
                            </Col>
                          )}
                          {!!stoForOrder.contactPhone2 && (
                            <Col
                              className="btn-link"
                              onClick={() =>
                                window.open(
                                  `tel:${stoForOrder.contactPhone2}`,
                                  "_self"
                                )
                              }
                            >
                              <span className="p-1">
                                <FaPhone />
                              </span>
                              {stoForOrder.contactPhone2}
                            </Col>
                          )}
                          <Row className="rating-text">
                            {!!stoForOrder.website && (
                              <Row>
                                <Row>{t("Website")}:</Row>
                                <ButtonGroup
                                  onClick={() =>
                                    window.open(stoForOrder.website, "_blank")
                                  }
                                  className="btn-mail d-flex align-items-center"
                                >
                                  <span className="p-1">
                                    <TbWorld />
                                  </span>
                                  {stoForOrder.website}
                                </ButtonGroup>
                              </Row>
                            )}

                            {!!stoForOrder.facebook && (
                              <Row>
                                <Row>{t("Facebook")}:</Row>
                                <ButtonGroup
                                  onClick={() =>
                                    window.open(stoForOrder.facebook, "_blank")
                                  }
                                  className="btn-mail d-flex align-items-center"
                                >
                                  <span className="p-1">
                                    <FaFacebook />
                                  </span>
                                  {stoForOrder.facebook}
                                </ButtonGroup>
                              </Row>
                            )}

                            {!!stoForOrder.instagram && (
                              <Row>
                                <Row>{t("Instagram")}:</Row>
                                <ButtonGroup
                                  onClick={() =>
                                    window.open(stoForOrder.instagram, "_blank")
                                  }
                                  className="btn-mail d-flex align-items-center"
                                >
                                  <span className="p-1">
                                    <FaInstagram />
                                  </span>
                                  {stoForOrder.instagram}
                                </ButtonGroup>
                              </Row>
                            )}

                            <Row>{t("Email")}:</Row>
                            <Button
                              onClick={() =>
                                window.open(
                                  `https://mail.google.com/mail/?view=cm&fs=1&to=${stoForOrder.contactMail}`,
                                  "_blank"
                                )
                              }
                              className="btn-mail"
                            >
                              <span className="p-1">
                                <FaMailBulk />
                              </span>
                              {stoForOrder.contactMail}
                            </Button>
                          </Row>
                        </Col>
                      </ListGroup.Item>
                    )}
                    <ListGroup.Item className="d-flex justify-content-center">
                      <Button
                        className="btn-block"
                        type="button"
                        onClick={() => setShowContacts(!showContacts)}
                      >
                        {showContacts ? t("HideContacts") : t("ShowContacts")}
                      </Button>
                    </ListGroup.Item>
                  </ListGroup>
                </Card>
              </Col>
            </Row>
          </ListGroup.Item>
        </ListGroup>
      </Col>
      <Col md={3}>
        <Card>
          <ListGroup variant="flush">
            <ListGroup.Item>
              <h2>{t("OrderSummary")}</h2>
            </ListGroup.Item>
            <ListGroup.Item>
              <Row>
                <Col>{t("Item")}</Col>
                <Col>${stoPriceWithoutTax}</Col>
              </Row>
            </ListGroup.Item>
            <ListGroup.Item>
              <Row>
                <Col>{t("Tax")}</Col>
                <Col>${stoTaxPrice}</Col>
              </Row>
            </ListGroup.Item>
            <ListGroup.Item>
              <Row>
                <Col>{t("Total")}</Col>
                <Col>${stoPrice}</Col>
              </Row>
            </ListGroup.Item>
            <ListGroup.Item>
              <div className="d-flex justify-content-center">
                <h6>{t("PAYTOPOST")}</h6>
              </div>
              <Form>
                <Form.Group>
                  <Col>
                    {(loadingPaypal || isPaymentLoading || isPending) && (
                      <Loader />
                    )}
                    <div>
                      <PayPalButtons
                        createOrder={createOrder}
                        onApprove={onApprove}
                        onError={onError}
                      ></PayPalButtons>
                    </div>
                  </Col>
                </Form.Group>
              </Form>
            </ListGroup.Item>
          </ListGroup>
        </Card>
      </Col>
    </Row>
  );
};

export { PlaceOrderPage };
