import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Form, Button, Col, Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Loader } from "../components";
import { useProfileMutation } from "../redux";
import { setCredentials } from "../redux";

export const ProfilePage = () => {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [image, setImage] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.login);
  const [updateProfile, { isLoading }] = useProfileMutation();

  const submitHandler = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      toast.error(`${t("PasswordsDoNotMatch")}`);
    } else {
      try {
        const res = await updateProfile({
          _id: userInfo._id,
          name,
          email,
          password,
        }).unwrap();
        dispatch(setCredentials(res));
        toast.success(`${t("ProfileUpdatedSuccessfully")}`);
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
    }
  };

  useEffect(() => {
    if (userInfo) {
      setName(userInfo.name);
      setEmail(userInfo.email);
      setImage(userInfo.image);
    }
  }, [userInfo, userInfo.name, userInfo.email, userInfo.image]);

  return (
    <Col md={6} className="d-flex flex-column m-auto">
      <h2>{t("UserProfile")}</h2>
      <Form onSubmit={submitHandler}>
        <Form.Group controlId="name" className="my-3">
          <Form.Label>{t("Name")}</Form.Label>
          <Form.Control
            type="name"
            placeholder={t("EnterName")}
            value={name}
            onChange={(e) => setName(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId="image" className="my-3 d-flex flex-column">
          <Form.Label>{t("Image")}</Form.Label>
          <Col className="d-flex justify-content-center">
            <Image
              className="mb-3"
              src={image}
              alt="avatar"
              thumbnail
              style={{
                height: "200px",
                width: "200px",
                borderRadius: "50%",
                objectFit: "cover",
              }}
            />
          </Col>
        </Form.Group>

        <Form.Group controlId="email" className="my-3">
          <Form.Label>{t("Email")}</Form.Label>
          <Form.Control
            type="email"
            placeholder={t("EnterEmail")}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId="password" className="my-3">
          <Form.Label>{t("Password")}</Form.Label>
          <Form.Control
            type="password"
            placeholder={t("EnterPassword")}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId="confirmPassword" className="my-3">
          <Form.Label>{t("ConfirmPassword")}</Form.Label>
          <Form.Control
            type="password"
            placeholder={t("EnterConfirmPassword")}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Button
          type="submit"
          variant="primary"
          className="mt-2"
          disabled={isLoading}
        >
          {t("Update")}
        </Button>
        {isLoading && <Loader />}
      </Form>
    </Col>
  );
};
