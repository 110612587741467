import { Outlet, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

export const UserStosRoute = () => {
  const { userStos } = useSelector((state) => state.item);
  return !!userStos && userStos.length !== 0 ? (
    <Outlet />
  ) : (
    <Navigate to="/" replace />
  );
};
