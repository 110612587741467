import { createSlice } from "@reduxjs/toolkit";
import { updateCart } from "../../utils/cart.utils.js";

const initialState = localStorage.getItem("cart")
  ? JSON.parse(localStorage.getItem("cart"))
  : {
      cartItems: [],
      stoForOrder: {},
      paymentMethod: "PayPal",
    };

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const newItem = action.payload;
      const existingItem = state.cartItems.find(
        (item) => item._id === newItem._id
      );

      if (existingItem) {
        state.cartItems = state.cartItems.map((item) =>
          item._id === existingItem._id ? newItem : item
        );
      } else {
        state.cartItems = [...state.cartItems, newItem];
      }

      return updateCart(state);
    },
    addStoForOrder: (state, action) => {
      state.stoForOrder = action.payload; // Ensure action.payload is serializable
      localStorage.setItem("cart", JSON.stringify(state));
      return state;
    },
    clearStoForOrder: (state) => {
      state.stoForOrder = {};
      localStorage.setItem("cart", JSON.stringify(state));
      return state;
    },
    removeFromCart: (state, action) => {
      state.cartItems = state.cartItems.filter(
        (item) => item._id !== action.payload
      );

      return updateCart(state);
    },
    cartReset: (state) => (state = initialState),
  },
});

export const {
  addToCart,
  addStoForOrder,
  removeFromCart,
  cartReset,
  clearStoForOrder,
} = cartSlice.actions;
export default cartSlice.reducer;
