import "leaflet/dist/leaflet.css";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMapEvents,
} from "react-leaflet";
import L from "leaflet";
import marker from "../assets/location.png";
import { Form } from "react-bootstrap";

const myIcon = new L.Icon({
  iconUrl: marker,
  iconRetinaUrl: marker,
  popupAnchor: [-0, -0],
  iconSize: [34, 45],
});

const RegistrationMap = ({ onCoordinateSelect }) => {
  const [position, setPosition] = useState(null);
  const { t } = useTranslation();

  const handleClick = (e) => {
    setPosition(e.latlng);
    onCoordinateSelect(e.latlng);
  };

  return (
    <MapContainer
      center={[49.2331, 28.4682]}
      zoom={6}
      style={{ height: "400px" }}
    >
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      {position && (
        <Marker position={position} icon={myIcon}>
          <Popup>{t("YouSelectedHere")}</Popup>
        </Marker>
      )}
      <MapClickHandler onClick={handleClick} />
    </MapContainer>
  );
};

function MapClickHandler({ onClick }) {
  useMapEvents({
    click: (e) => {
      onClick(e);
    },
  });
  return null;
}

export const LocationPicker = ({
  coordinates,
  setCoordinates,
  address,
  setAddress,
}) => {
  const handleCoordinateSelect = async (coordinate) => {
    setCoordinates(coordinate);
    const response = await fetch(
      `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${coordinate.lat}&lon=${coordinate.lng}`
    );
    const data = await response.json();
    setAddress(data.display_name);
  };
  const { t } = useTranslation();

  return (
    <div>
      {/* <div className="mt-3"> */}
      <Form.Label style={{ fontSize: "24px", fontWeight: "500" }}>
        {t("RegisterOnMap")}
        <span style={{ color: "#c60909" }}>*</span>
      </Form.Label>
      <p>{t("ClickOnMapToSelectRegistrationCoordinates")}</p>
      <RegistrationMap onCoordinateSelect={handleCoordinateSelect} />
      <div>
        <Form.Group controlId="locationCoordinates" className="mt-1">
          <Form.Control
            readOnly
            required
            type="text"
            placeholder={
              t("Coordinates") + t("IsAutomaticallySetAfterChoosingOnMap")
            }
            value={
              coordinates
                ? `${coordinates?.lat}, ${coordinates?.lng}`
                : coordinates
            }
            className="p-1"
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId="locationName" className="mt-1 mb-3">
          <Form.Control
            readOnly
            required
            type="text"
            placeholder={
              t("Location") + t("IsAutomaticallySetAfterChoosingOnMap")
            }
            value={address}
            className="p-1"
          ></Form.Control>
        </Form.Group>
      </div>
    </div>
  );
};
