import { Link } from "react-router-dom";
import { Carousel, Image } from "react-bootstrap";
import { Loader, Rating } from "../components";
import { useEffect, useState } from "react";

export const ItemsCarousel = ({ unit = "sto", items, isLoad }) => {
  const [arrayItems, setArrayItems] = useState([]);
  useEffect(() => {
    if (items) {
      setArrayItems(items);
    }
  }, [items]);

  return isLoad ? (
    <Loader />
  ) : (
    <Carousel pause="hover" className="bg-primary mb-4">
      {arrayItems.map((item) => (
        <Carousel.Item key={item._id}>
          <Link to={`/${unit}/${item._id}`} className="d-flex flex-colum ">
            <Image
              src={item.image}
              alt={item.name}
              className="carousel-image"
            />

            <Carousel.Caption className="carousel-caption">
              <h2 style={{ color: "var(--bs-navbar-active-color)" }}>
                {item.name}
                <Rating
                  value={item.rating}
                  text={`${item.numReviews} reviews`}
                />
              </h2>
            </Carousel.Caption>
          </Link>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};
