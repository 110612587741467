import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useConfirmEmailMutation, setCredentials } from "../redux";
import { Loader } from "../components";
import { useLocation, useNavigate } from "react-router-dom";
import { Container, Col, Button, Card } from "react-bootstrap";

export const ConfirmEmailPage = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [confirmEmail, { isLoading }] = useConfirmEmailMutation();

  const query = new URLSearchParams(location.search);
  const token = query.get("token");

  const confirmEmailHandler = async () => {
    try {
      const { user } = await confirmEmail({ token }).unwrap();
      dispatch(setCredentials(user));
      navigate("/home");
      toast.success("Welcome to AMMECHANIC !");
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  return (
    <Container className="d-flex justify-content-center align-items-center mt-5 p-4">
      <Col xs={12} md={8} lg={9}>
        <Card className="text-center p-2">
          <Card.Body>
            <h5>Registration Submitting</h5>
            <Card.Text>
              A confirmation email sent to your email address was the second
              step. Last step to finish sign up process and join out community
              is to click submit button below. We will be happy to have you!
            </Card.Text>
            <Button
              variant="info"
              className="mt-2 px-4 text-light"
              onClick={confirmEmailHandler}
              disabled={isLoading}
            >
              Submit registration
            </Button>
            {isLoading && <Loader />}
          </Card.Body>
        </Card>
      </Col>
    </Container>
  );
};
