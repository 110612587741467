import React, { useState } from "react";
import { Modal, Button, Dropdown, DropdownButton, Col } from "react-bootstrap";
import { BiCurrentLocation } from "react-icons/bi";
import { FaMapMarkedAlt } from "react-icons/fa";
import { IoCloseOutline } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { LocationPicker } from "./LocationPicker";
import { useSearchParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export const LocationSelect = ({ near }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [selectedOption, setSelectedOption] = useState("");
  const [coordinates, setCoordinates] = useState(null);
  const [address, setAddress] = useState("");
  const [showModal, setShowModal] = useState(false);

  const { t } = useTranslation();

  const handleSetNearCoordinates = (coordinates) => {
    setCoordinates({ lat: coordinates.lat, lng: coordinates.lng });
  };

  const handleSetNearCoordinatesQuery = (coordinates) => {
    if (!coordinates) {
      toast.error("Please choose location on map");
    }
    searchParams.delete("page");
    searchParams.set("near", `${coordinates.lat},${coordinates.lng}`);
    setShowModal(false);
    navigate({
      pathname: "/services",
      search: searchParams.toString(),
    });
  };

  const delParams = () => {
    searchParams.delete("near");
    searchParams.delete("page");
    navigate({
      pathname: "/services",
      search: searchParams.toString(),
    });
  };

  const handleOptionChange = (option) => {
    setSelectedOption(option);

    if (option === "Sort by distance to chosen location") {
      setShowModal(true);
    } else if (option === "Sort by distance to my location") {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setCoordinates({ lat: latitude, lng: longitude });

            handleSetNearCoordinatesQuery({ lat: latitude, lng: longitude });
          },
          (error) => {
            console.error("Error obtaining location", error);
            alert(t("Unable to retrieve your location"));
          }
        );
      } else {
        alert(t("Geolocation is not supported by this browser."));
      }
    } else if (!option || option === "") {
      delParams();
    }
  };

  return (
    <div>
      <DropdownButton
        title={selectedOption || t("Sort by Distance")}
        onSelect={handleOptionChange}
        className={
          selectedOption === "" && !near
            ? "transparent-dropdown-button btn"
            : "transparent-dropdown-button btn btn-primary"
        }
        style={{
          borderRadius: "20px",
          border: "1px solid",
          padding: "0 12px",
        }}
      >
        <Dropdown.Item eventKey="" active={false}>
          {t("Sort by Distance")}
        </Dropdown.Item>
        <Dropdown.Item
          eventKey="Sort by distance to my location"
          active={selectedOption === "Sort by distance to my location"}
        >
          <BiCurrentLocation className="fs-5" /> {t("My Location")}
        </Dropdown.Item>
        <Dropdown.Item
          eventKey="Sort by distance to chosen location"
          active={selectedOption === "Sort by distance to chosen location"}
        >
          <FaMapMarkedAlt className="m-1 fs-5-6" />{" "}
          {t("Choose location on map")}
        </Dropdown.Item>
      </DropdownButton>

      <Modal
        show={showModal}
        onHide={() => {
          setShowModal(false);
          setSelectedOption("");
          delParams();
        }}
      >
        <Modal.Body>
          <Col
            className="w-100 text-end fs-2"
            style={{position:"relative", marginBottom:"-30px"}}
            onClick={() => {
              setShowModal(false);
              setSelectedOption("");
              delParams();
            }}
          >
            <IoCloseOutline />
          </Col>
          <LocationPicker
            coordinates={coordinates}
            setCoordinates={handleSetNearCoordinates}
            address={address}
            setAddress={setAddress}
          />
          <Button
            variant="secondary"
            onClick={() => handleSetNearCoordinatesQuery(coordinates)}
            className="w-100 py-1"
          >
            {t("Submit")}
          </Button>
        </Modal.Body>
      </Modal>
    </div>
  );
};
