import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { FormContainer, Loader } from "../components";
import {
  setCredentials,
  useLoginMutation,
  setMechanic,
  setUserStos,
  addStoForOrder,
} from "../redux";
import { PasswordValidator } from "../validators";

export const LoginPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [login, { isLoading }] = useLoginMutation();
  const { userInfo } = useSelector((state) => state.login);
  const { search } = useLocation();
  const sp = new URLSearchParams(search);
  const redirect = sp.get("redirect") || "/";

  const submitHandler = async (e) => {
    e.preventDefault();
    if (!PasswordValidator(password)) {
      toast.error(`${t("InvalidEmailOrPassword")}`);
      return;
    } else {
      try {
        const { user, mechanic, userStos, stoForOrder } = await login({
          email,
          password,
        }).unwrap();
        if (user) {
          dispatch(setCredentials(user));
        } else {
          toast.error(`${t("InvalidEmailOrPassword")}`);
        }
        if (mechanic) {
          dispatch(setMechanic(mechanic));
        }
        if (stoForOrder) {
          dispatch(addStoForOrder(stoForOrder));
        }
        if (!!userStos && userStos.length !== 0) {
          dispatch(setUserStos(userStos));
        }
        navigate(redirect);
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
    }
  };

  useEffect(() => {
    if (userInfo) {
      navigate(redirect);
    }
  }, [userInfo, redirect, navigate]);

  return (
    <FormContainer>
      <h1>{t("SignIn")}</h1>

      <Form onSubmit={submitHandler}>
        <Form.Group controlId="email" className="my-3">
          <Form.Label>{t("Email")}</Form.Label>
          <Form.Control
            type="email"
            placeholder={t("EnterEmail")}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId="password" className="my-3">
          <Form.Label>{t("Password")}</Form.Label>
          <Form.Control
            type="password"
            maxLength={100}
            placeholder={t("EnterPassword")}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            isInvalid={!(PasswordValidator(password) || password === "")}
          ></Form.Control>
          <Form.Control.Feedback type="invalid">
            {t("PasswordRecomendations")}
          </Form.Control.Feedback>
        </Form.Group>

        <Button
          type="submit"
          variant="primary"
          className="mt-2"
          disabled={isLoading}
        >
          {t("SignIn")}
        </Button>
        {isLoading && <Loader />}
      </Form>

      <Row className="py-3">
        <Col>
          {t("NewCustomer")}
          <Link to={redirect ? `/register?redirect=${redirect}` : "/register"}>
            {t("Register")}
          </Link>
        </Col>
      </Row>
    </FormContainer>
  );
};
