import {
  USERS_URL,
  PAYPAL_URL,
  FEEDBACK_URL,
  UPLOADS_URL,
} from "../../../constants";
import { apiSlice } from "./api.slice.js";

export const userSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (data) => ({
        url: `${USERS_URL}/login`,
        method: "POST",
        body: data,
      }),
    }),
    register: builder.mutation({
      query: (data) => ({
        url: USERS_URL,
        method: "POST",
        body: data,
      }),
    }),
    logout: builder.mutation({
      query: () => ({
        url: `${USERS_URL}/logout`,
        method: "POST",
      }),
    }),
    profile: builder.mutation({
      query: (data) => ({
        url: `${USERS_URL}/profile`,
        method: "PUT",
        body: data,
      }),
    }),
    getUsers: builder.query({
      query: ({ keyword, page }) => {
        const params = {};

        if (!!keyword && keyword != null && keyword !== "null")
          params.keyword = keyword;
        if (!!page && page != null && page !== "null") params.page = page;

        return {
          url: USERS_URL,
          params,
        };
      },
      providesTags: ["User"],
      keepUnusedDataFor: 5,
    }),
    getUserDetails: builder.query({
      query: (userId) => ({
        url: `${USERS_URL}/${userId}`,
      }),
      keepUnusedDataFor: 5,
    }),
    updateUser: builder.mutation({
      query: ({ userId, ...data }) => ({
        url: `${USERS_URL}/${userId}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["User"],
    }),
    deleteUser: builder.mutation({
      query: (userId) => ({
        url: `${USERS_URL}/${userId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["User"],
    }),
    getPaypalClientId: builder.query({
      query: () => ({
        url: PAYPAL_URL,
      }),
      keepUnusedDataFor: 5,
    }),
    postClientFeedback: builder.mutation({
      query: (data) => ({
        url: FEEDBACK_URL,
        method: "POST",
        body: data,
      }),
    }),
    uploadImage: builder.mutation({
      query: (data) => ({
        url: UPLOADS_URL,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["User"],
    }),
    updateUserBlockStatus: builder.mutation({
      query: (userId) => ({
        url: `${USERS_URL}/admin/${userId}/block`,
        method: "PUT",
      }),
      invalidatesTags: ["User"],
    }),
    confirmEmail: builder.mutation({
      query: ({ token }) => ({
        url: `${USERS_URL}/confirm-email?token=${token}`,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useLogoutMutation,
  useRegisterMutation,
  useProfileMutation,
  useGetUsersQuery,
  useDeleteUserMutation,
  useGetUserDetailsQuery,
  useUpdateUserMutation,
  useGetPaypalClientIdQuery,
  useUploadImageMutation,
  useUpdateUserBlockStatusMutation,
  usePostClientFeedbackMutation,
  useConfirmEmailMutation,
} = userSlice;
