import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const NotFoundPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div>
      <h1>{t("NotFoundPage")}</h1>
      <Button onClick={() => navigate("/stos")}>{t("BackToSite")}</Button>
    </div>
  );
};

export { NotFoundPage };
