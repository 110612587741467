import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { Row, Button, Col, Form } from "react-bootstrap";
import {
  addStoForOrder,
  useAddStoOrderByUserMutation,
  useUpdateStoOrderByUserMutation,
} from "../redux";
import { useNavigate } from "react-router-dom";

import {
  FormContainer,
  ImagePicker,
  Timetable,
  LocationPicker,
  Asterisk,
} from "../components";
import { experienceOptions, addPhoneSpace } from "../functions";
import {
  EmailValidator,
  NameValidator,
  PhoneValidator,
  UrlValidator,
  FacebookUrlValidator,
  InstagramUrlValidator,
} from "../validators";

export const StoRegisterPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [image, setImage] = useState(null);
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("any");
  const [brand, setBrand] = useState("Any");
  const [experience, setExperience] = useState(0);
  const [isOnTheGo, setIsOnTheGo] = useState(false);
  const [contactPhone1, setContactPhone1] = useState("");
  const [contactPhone2, setContactPhone2] = useState("");
  const [contactMail, setContactMail] = useState("");
  const [website, setWebsite] = useState("");
  const [instagram, setInstagram] = useState("");
  const [facebook, setFacebook] = useState("");
  const [coordinates, setCoordinates] = useState("");
  const [address, setAddress] = useState("");
  const [timetable, setTimetable] = useState({
    monday: { closed: false, opening: "09:00", closing: "18:00" },
    tuesday: { closed: false, opening: "09:00", closing: "18:00" },
    wednesday: { closed: false, opening: "09:00", closing: "18:00" },
    thursday: { closed: false, opening: "09:00", closing: "18:00" },
    friday: { closed: false, opening: "09:00", closing: "18:00" },
    saturday: { closed: true, opening: "10:00", closing: "16:00" },
    sunday: { closed: true, opening: "10:00", closing: "16:00" },
  });

  const { stoForOrder } = useSelector((state) => state.cart);

  const [addStoOrder, { isLoading }] = useAddStoOrderByUserMutation();
  const [updateStoOrder, { isLoading: isUpdating }] =
    useUpdateStoOrderByUserMutation();

  const submitHandler = async (e) => {
    e.preventDefault();
    if (!NameValidator(name)) {
      toast.error(`${t("PleaseEnterValidName")}`);
      return;
    }
    if (!!contactPhone1 && !PhoneValidator(contactPhone1)) {
      toast.error(`${t("PleaseEnterValidPhoneNumber")}`);
      return;
    }
    if (!!contactPhone2 && !PhoneValidator(contactPhone2)) {
      toast.error(`${t("PleaseEnterValidPhoneNumber")}`);
      return;
    }

    if (!EmailValidator(contactMail)) {
      toast.error(`${t("PleaseEnterValidContactMail")}`);
      return;
    }

    if (!coordinates || !address) {
      toast.error(`${t("PleaseSelectLocationOnMap")}`);
      return;
    }

    if (!!website && !UrlValidator(website)) {
      toast.error(`${t("PleaseEnterValidWebsiteUrl")}`);
      return;
    }
    if (!!facebook && !FacebookUrlValidator(facebook)) {
      toast.error(`${t("PleaseEnterValidFacebookUrl")}`);
      return;
    }
    if (!!instagram && !InstagramUrlValidator(instagram)) {
      toast.error(`${t("PleaseEnterValidInstagramUrl")}`);
      return;
    }
    const newSto = {
      name,
      image,
      description,
      category,
      brand,
      experience,
      timetable,
      isOnTheGo,
      coordinates,
      address,
      contactPhone1,
      contactPhone2,
      contactMail,
      website,
      instagram,
      facebook,
    };
    if (JSON.stringify(stoForOrder) !== "{}") {
      const { isEqual, keysDifferent } = compareStos(stoForOrder, newSto);

      if (isEqual) {
        navigate("/placeorder");
        return;
      } else {
        const updatedFields = { ...keysDifferent, _id: stoForOrder._id };
        const { sto } = await updateStoOrder(updatedFields).unwrap();
        if (sto) {
          dispatch(addStoForOrder(sto)); // add sto to user stos with status in order
          navigate("/placeorder");
        }
      }
    } else {
      try {
        const { sto } = await addStoOrder(newSto).unwrap();
        if (sto) {
          dispatch(addStoForOrder(sto));
          navigate("/placeorder");
        }
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
    }
  };

  useEffect(() => {
    const isStoForOrder = !!stoForOrder;

    const propOrString = (value, orValue = "") => {
      return isStoForOrder && !!stoForOrder?.[value]
        ? stoForOrder?.[value]
        : orValue;
    };

    setName(propOrString("name"));
    setImage(propOrString("image", null));
    setDescription(propOrString("description"));
    setCategory(propOrString("category", "any"));
    setBrand(propOrString("brand", "Any"));
    setExperience(propOrString("experience", 0));
    setIsOnTheGo(propOrString("isOnTheGo", false));
    setContactPhone1(propOrString("contactPhone1"));
    setContactPhone2(propOrString("contactPhone2"));
    setContactMail(propOrString("contactMail"));
    setWebsite(propOrString("website"));
    setInstagram(propOrString("instagram"));
    setFacebook(propOrString("facebook"));
    setCoordinates(propOrString("coordinates"));
    setAddress(propOrString("address"));
    setTimetable(
      propOrString("timetable", {
        monday: { closed: false, opening: "09:00", closing: "18:00" },
        tuesday: { closed: false, opening: "09:00", closing: "18:00" },
        wednesday: { closed: false, opening: "09:00", closing: "18:00" },
        thursday: { closed: false, opening: "09:00", closing: "18:00" },
        friday: { closed: false, opening: "09:00", closing: "18:00" },
        saturday: { closed: true, opening: "10:00", closing: "16:00" },
        sunday: { closed: true, opening: "10:00", closing: "16:00" },
      })
    );
  }, [stoForOrder]);

  function compareStos(item1, item2) {
    let keysDifferent = {};
    const keysToCompare = [
      "name",
      "image",
      "description",
      "category",
      "brand",
      "experience",
      "isOnTheGo",
      "contactPhone1",
      "contactPhone2",
      "contactMail",
      "website",
      "instagram",
      "facebook",
      "coordinates",
      "address",
      "timetable",
    ];

    for (const key of keysToCompare) {
      if (item2.hasOwnProperty(key)) {
        if (item1.hasOwnProperty(key)) {
          if (JSON.stringify(item1[key]) !== JSON.stringify(item2[key])) {
            keysDifferent[key] = item2[key];
          }
        } else {
          if (item2[key] !== "") {
            keysDifferent[key] = item2[key];
          }
        }
      }
    }

    if (Object.keys(keysDifferent).length > 0) {
      return {
        isEqual: false,
        keysDifferent,
      };
    } else {
      return {
        isEqual: true,
      };
    }
  }

  return (
    <FormContainer>
      <h1>{t("RegisterCSS")}</h1>
      <Form onSubmit={submitHandler}>
        <Form.Group controlId="name" className="my-3">
          <Form.Label>
            {t("Name")} <Asterisk />
          </Form.Label>
          <Form.Control
            type="name"
            placeholder={t("EnterName")}
            value={name}
            maxLength={100}
            minLength={3}
            onChange={(e) => setName(e.target.value)}
            required
            isInvalid={!(NameValidator(name) || name === "")}
          ></Form.Control>
          <Form.Control.Feedback type="invalid">
            {t("PleaseUseOnlyLettersAndDigits")}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="contactPhone1" className="my-3">
          <Form.Label>{t("ContactPhone")}</Form.Label>
          <Form.Control
            type="tel"
            placeholder={t("EnterMechanicContactPhone")}
            value={contactPhone1}
            maxLength={12}
            onChange={(e) => setContactPhone1(addPhoneSpace(e))}
            isInvalid={!(PhoneValidator(contactPhone1) || contactPhone1 === "")}
          ></Form.Control>
          <Form.Control.Feedback type="invalid">
            {t("PleaseEnterValidPhoneNumberX")}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="contactMail" className="my-3">
          <Form.Label>
            {t("ContactMail")}
            <Asterisk />
          </Form.Label>
          <Form.Control
            type="email"
            maxLength={100}
            placeholder={t("EnterMechanicContactMail")}
            value={contactMail}
            required
            onChange={(e) => setContactMail(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <ImagePicker setImage={setImage} />

        <Form.Group controlId="isOnTheGo" className="my-3">
          <Form.Label>
            {t("OnTheGo")} <Asterisk />
          </Form.Label>
          <Form.Control
            as="select"
            placeholder={t("SpecifyIfYouCanGiveAwayService")}
            onChange={(e) => setIsOnTheGo(e.target.value)}
            value={isOnTheGo}
          >
            <option value="true">{t("Yes")}</option>
            <option value="false">{t("No")}</option>
          </Form.Control>
        </Form.Group>

        <Form.Label>
          {t("Timetable")} <Asterisk />
        </Form.Label>
        <Timetable timetable={timetable} setTimetable={setTimetable} />

        <LocationPicker
          coordinates={coordinates}
          setCoordinates={setCoordinates}
          address={address}
          setAddress={setAddress}
        />

        <Form.Group controlId="description" className="mb-3">
          <Form.Label>{t("Description")}</Form.Label>
          <Form.Control
            as="textarea"
            placeholder={t("EnterYourServicesDescription")}
            value={description}
            rows={4}
            onChange={(e) => setDescription(e.target.value)}
            maxLength={500}
            style={{ overflow: "auto" }}
          ></Form.Control>
          <Form.Text>
            {500 - description.length}/500 {t("symbols")}
          </Form.Text>
        </Form.Group>

        <Form.Group controlId="category" className="my-3">
          <Form.Label>{t("Category")}</Form.Label>
          <Form.Control
            as="select"
            placeholder={t("ChooseYourCategory")}
            onChange={(e) => setCategory(e.target.value)}
            value={category}
          >
            <option value="any">{t("Any")}</option>
            <option value="cars">{t("Cars")}</option>
            <option value="trucks">{t("Trucks")}</option>
            <option value="buses">{t("Buses")}</option>
            <option value="trucks">{t("Trailers")}</option>
            <option value="specialmachinery">{t("SpecialMachinery")}</option>
            <option value="agriculturalmachinery">
              {t("AgriculturalMachinery")}
            </option>
            <option value="watertransport">{t("WaterTransport")}</option>
            <option value="airtransport">{t("AirTransport")}</option>
            <option value="motorhomes">{t("Motorhomes")}</option>
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="brand" className="my-3">
          <Form.Label>{t("Brand")}</Form.Label>
          <Form.Control
            as="select"
            placeholder={t("ChooseYourBrand")}
            onChange={(e) => setBrand(e.target.value)}
            value={brand}
          >
            <option value="0">{t("Any")}</option>
            <option value="1">Audi</option>
            <option value="2">Shevrolet</option>
            <option value="3">Bmw</option>
            <option value="4">Ford</option>
            <option value="5">Honda</option>
            <option value="6">Hyundai</option>
            <option value="7">Kia</option>
            <option value="8">Lexus</option>
            <option value="9">Mazda</option>
            <option value="10">Mercedes-Benz</option>
            <option value="11">Mitsubishi</option>
            <option value="12">Nissan</option>
            <option value="13">Opel</option>
            <option value="14">Peugeot</option>
            <option value="15">Renault</option>
            <option value="16">Skoda</option>
            <option value="17">Toyota</option>
            <option value="18">Volkswagen</option>
            <option value="19">Volvo</option>
            <option value="20">Waz/Lada</option>
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="experience" className="my-3">
          <Form.Label>{t("Experience")}</Form.Label>
          <Form.Control
            as="select"
            value={experience}
            onChange={(e) => setExperience(e.target.value)}
          >
            {experienceOptions()}
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="contactPhone2" className="my-3">
          <Form.Label>{t("ContactPhone2")}</Form.Label>
          <Form.Control
            type="tel"
            placeholder={t("EnterCSScontactPhone2")}
            value={contactPhone2}
            maxLength={12}
            onChange={(e) => setContactPhone2(addPhoneSpace(e))}
            isInvalid={!(PhoneValidator(contactPhone2) || contactPhone2 === "")}
          ></Form.Control>
          <Form.Control.Feedback type="invalid">
            {t("PleaseEnterValidPhoneNumberX")}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="website" className="my-3">
          <Form.Label>{t("Website")}</Form.Label>
          <Form.Control
            type="url"
            placeholder={t("EnterYourWebsiteUrl")}
            value={website}
            onChange={(e) => setWebsite(e.target.value)}
            maxLength={100}
            isInvalid={!(UrlValidator(website) || website === "")}
          ></Form.Control>
          <Form.Control.Feedback type="invalid">
            {t("PleaseEnterValidWebsiteUrl")}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="facebook" className="my-3">
          <Form.Label>{t("Facebook")}</Form.Label>
          <Form.Control
            type="url"
            placeholder={t("EnterYourFacebookUrl")}
            value={facebook}
            onChange={(e) => setFacebook(e.target.value)}
            maxLength={100}
            isInvalid={!(FacebookUrlValidator(facebook) || facebook === "")}
          ></Form.Control>
          <Form.Control.Feedback type="invalid">
            {t("PleaseEnterValidFacebookUrl")}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="instagram" className="my-3">
          <Form.Label>{t("Instagram")}</Form.Label>
          <Form.Control
            type="url"
            placeholder={t("EnterYourInstagramUrl")}
            value={instagram}
            onChange={(e) => setInstagram(e.target.value)}
            maxLength={100}
            isInvalid={!(InstagramUrlValidator(instagram) || instagram === "")}
          ></Form.Control>
          <Form.Control.Feedback type="invalid">
            {t("PleaseEnterValidInstagramUrl")}
          </Form.Control.Feedback>
        </Form.Group>

        <Button
          type="submit"
          variant="primary"
          className="mt-2"
          disabled={isLoading || isUpdating}
        >
          {t("PlaceOrder")}
        </Button>
      </Form>

      <Row className="py-3">
        <Col>
          {t("AlreadyHaveRegisteredCSS")}
          <Link to={"/mystos"}>{t("mycss")}</Link>
        </Col>
      </Row>
    </FormContainer>
  );
};
