export const experienceOptions = () => {
  const arrOpt = [];
  for (let i = 0; i < 20; i++) {
    // const element = array[index];
    arrOpt.push(<option key={i} value={i}>{i}</option>);
    arrOpt.push(<option key={i+0.5} value={i + 0.5}>{i + 0.5}</option>);
  }
  arrOpt.push(<option key={21} value={21}>20+</option>);
  return arrOpt;
};
