import React from "react";
import { useTranslation } from "react-i18next";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { MdLanguage } from "react-icons/md";

export const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const handleLanguageChange = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className="d-flex justify-content-center align-items-center">
      <DropdownButton
        id="dropdown-basic-button"
        title={
          <span>
            <MdLanguage /> {currentLanguage}
          </span>
        }
        className="transparent-dropdown-button"
      >
        <Dropdown.Item onClick={() => handleLanguageChange("en")}>
          🇬🇧 en
        </Dropdown.Item>
        <Dropdown.Item onClick={() => handleLanguageChange("ua")}>
          🇺🇦 ua
        </Dropdown.Item>
      </DropdownButton>
    </div>
  );
};
