import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { NavDropdown } from "react-bootstrap";
import { useLogoutMutation, itemsReset, userLogout, cartReset } from "../redux";

export const LogoutBtn = () => {
  const { t } = useTranslation();
  const [logoutApiCall] = useLogoutMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = async () => {
    try {
      await logoutApiCall().unwrap();
      dispatch(userLogout());
      dispatch(itemsReset());
      dispatch(cartReset());
      navigate("/login");
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <NavDropdown.Item onClick={handleLogout}>{t("Logout")}</NavDropdown.Item>
  );
};
