import { Row, Col, Container, Button, Form, Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { usePostClientFeedbackMutation } from "../redux";
import { Loader } from "../components";

export const FeedbackForm = () => {
  const [formData, setFormData] = useState({
    title: "",
    message: "",
  });
  const [userEmail, setUserEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const { t } = useTranslation();
  const [postFeedback, { isLoading }] = usePostClientFeedbackMutation();
  const { userInfo } = useSelector((state) => state.login);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!userEmail)
      return toast.error(
        <div>
          Log in to leave a feedback.
          <br />
          <Link to="/login">{t("Login")}</Link> /{" "}
          <Link to="/register">{t("Register")}</Link>
        </div>
      );
    try {
      await postFeedback({
        ...formData,
        email: userEmail,
      }).unwrap();
      setSubmitted(true);
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };
  useEffect(() => {
    if (userInfo?.email) {
      setUserEmail(userInfo.email);
    }
  }, [userInfo?.email]);

  return (
    <Container>
      <Row className="mt-3">
        <h5 className="text-center">{t("WeValueYourFeedback")}</h5>
        <p>{t("CommittedToConstantImprovement")}</p>
        <p>{t("HearYourFeedback")}</p>
      </Row>
      <Row>
        <Col md={6}>
          <h3>{t("FeedbackForm")}</h3>
          {submitted ? (
            <Alert variant="success">{t("ThankYouForYourFeedback")}</Alert>
          ) : (
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formEmail">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type="textarea"
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                  rows={1}
                  maxLength={200}
                  style={{ resize: "none" }}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formMessage">
                <Form.Label>{t("Message")}</Form.Label>
                <Form.Control
                  as="textarea"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  rows={3}
                  maxLength={2000}
                  style={{ resize: "none" }}
                  required
                />
              </Form.Group>
              {isLoading ? (
                <Loader />
              ) : (
                <Button variant="primary" type="submit" className="mt-2">
                  {t("Send")}
                </Button>
              )}
            </Form>
          )}
        </Col>
      </Row>
    </Container>
  );
};
