import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userStos: localStorage.getItem("userStos")
    ? JSON.parse(localStorage.getItem("userStos"))
    : [],
  mechanic: localStorage.getItem("mechanic")
    ? JSON.parse(localStorage.getItem("mechanic"))
    : null,
};

const itemSlice = createSlice({
  name: "item",
  initialState,
  reducers: {
    setUserStos: (state, action) => {
      const userStos = action.payload;
      if (Array.isArray(userStos) && userStos.length > 0) {
        state.userStos = userStos;
      }
      localStorage.setItem("userStos", JSON.stringify(state.userStos));
    },
    setMechanic: (state, action) => {
      state.mechanic = action.payload;
      localStorage.setItem("mechanic", JSON.stringify(action.payload));
    },
    addUserStos: (state, action) => {
      const sto = action.payload;
      state.userStos.push(sto);
      localStorage.setItem("userStos", JSON.stringify(state.userStos));
    },
    itemsReset: (state) => (state = initialState),
  },
});

export const { setUserStos, itemsReset, addUserStos, setMechanic } =
  itemSlice.actions;

export default itemSlice.reducer;
