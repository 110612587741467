import { Navbar, Nav, Container, Badge, NavDropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaUser, FaBookmark, FaTools } from "react-icons/fa";
import { MdOutlineAddLocationAlt } from "react-icons/md";
import Iicon from "../assets/icons8-i-64.png";
import Micon from "../assets/m2_icon.png";
// import ColorHandIcon from "../assets/icons8-mechanic-64.png";
import ColorRozborkaIcon from "../assets/crashedcar.png";
// import mechanicProfileIcon from "../assets/icons8-mechanic-64 (7).png";
import apostropheIcon from "../assets/apostrophe.png";
import { LinkContainer } from "react-router-bootstrap";
import { useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { LogoutBtn, SearchBox, LanguageSwitcher } from "../components";
import { useLocation } from "react-router-dom";
import { BiSolidCarGarage } from "react-icons/bi";

export const Header = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const { cartItems } = useSelector((state) => state.cart);
  const { userInfo } = useSelector((state) => state.login);
  const {
    userStos,
    //  mechanic
  } = useSelector((state) => state.item);

  return (
    <header>
      <Navbar bg="black" variant="dark" expand="md" collapseOnSelect>
        <Container>
          <LinkContainer to="/">
            <Navbar.Brand>
              <img
                src={Iicon}
                height="30"
                width="30"
                className="d-inline-block align-top"
                alt="AmMechanic"
              />
              <img
                src={apostropheIcon}
                height="15"
                width="15"
                className="d-inline-block align-top"
                alt="AmMechanic"
              />
              <img
                src={Micon}
                height="30"
                width="30"
                className="d-inline-block align-top"
                alt="AmMechanic"
              />
              echanic
            </Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Col
                md={8}
                className="d-flex flex-md-row flex-wrap flex-wrap flex-column"
              >
                {/* {!mechanic && (
                  <LinkContainer to="/mechanicregister">
                    <Nav.Link className="align-center-gap0_5">
                      <Row className="d-flex align-items-center nav-link p-0">
                        <Col
                          className="p-0"
                          style={{ paddingInline: "0.3rem" }}
                        >
                          <img
                            src={ColorHandIcon}
                            height="30"
                            width="30"
                            className="d-inline-block align-top"
                            alt="AmMechanic"
                            style={{ marginLeft: "-6px" }}
                          />
                        </Col>
                        <Col className="d-flex flex-column align-items-center p-0">
                          <Row className="p-0" style={{ lineHeight: "0.9" }}>
                            {t("Become")}
                          </Row>
                          <Row className="p-0" style={{ lineHeight: "0.9" }}>
                            {t("Mechanicom")}
                          </Row>
                        </Col>
                      </Row>
                    </Nav.Link>
                  </LinkContainer>
                )} */}
                {!false && (
                  <LinkContainer to="/mechanicregister">
                    <Nav.Link className="align-center-gap0_5">
                      <Row className="d-flex align-items-center nav-link p-0">
                        <Col
                          className="p-0"
                          style={{ paddingInline: "0.3rem" }}
                        >
                          <img
                            src={ColorRozborkaIcon}
                            height="30"
                            width="30"
                            className="d-inline-block align-top"
                            alt="AmMechanic"
                            style={{ marginLeft: "-6px" }}
                          />
                        </Col>
                        <Col className="d-flex flex-column align-items-center p-0">
                          {t("Розборки")}
                        </Col>
                      </Row>
                    </Nav.Link>
                  </LinkContainer>
                )}
                <LinkContainer to="/addservice">
                  <Nav.Link className="align-center-gap0_5">
                    <MdOutlineAddLocationAlt /> {t("AddService")}
                  </Nav.Link>
                </LinkContainer>
                <LinkContainer to="/cart">
                  <Nav.Link className="align-center-gap0_5">
                    <FaBookmark /> {t("Saved")}
                    {cartItems.length > 0 && (
                      <Badge pill style={{ marginLeft: "5px" }}>
                        {cartItems.reduce((a, i) => a + i.qty, 0)}
                      </Badge>
                    )}
                  </Nav.Link>
                </LinkContainer>
                <LinkContainer to="/services">
                  <Nav.Link className="align-center-gap0_5">
                    <FaTools /> Services
                  </Nav.Link>
                </LinkContainer>

                {location.pathname.startsWith("/services") && <SearchBox />}
              </Col>

              <Col
                md={4}
                className="d-flex flex-md-row flex-wrap flex-column justify-content-end"
              >
                {/* {mechanic && (
                  <LinkContainer to="/mechanic-profile">
                    <Nav.Link className="align-center-gap0_5">
                      <Row className=" d-flex align-items-center nav-link pb-0">
                        <Col style={{ paddingInline: "0.3rem" }}>
                          <img
                            src={mechanicProfileIcon}
                            height="30"
                            width="30"
                            className="d-inline-block align-top"
                            alt="AmMechanic"
                          />
                        </Col>
                        <Col className="d-flex flex-column align-items-center">
                          <Row className="p-0" style={{ lineHeight: "0.9" }}>
                            {t("Mechanic")}
                          </Row>
                          <Row className="p-0" style={{ lineHeight: "0.9" }}>
                            {t("Profile")}
                          </Row>
                        </Col>
                      </Row>
                    </Nav.Link>
                  </LinkContainer>
                )} */}
                {!!userStos && userStos.length !== 0 && (
                  <LinkContainer to="/mystos">
                    <Nav.Link className="align-center-gap0_5">
                      <BiSolidCarGarage />
                      <Col>{t("mycss")}</Col>
                    </Nav.Link>
                  </LinkContainer>
                )}
                {userInfo ? (
                  <NavDropdown
                    title={userInfo.name}
                    id="username"
                    className="align-center-gap0_5"
                  >
                    <LinkContainer to="/profile">
                      <NavDropdown.Item>{t("Profile")}</NavDropdown.Item>
                    </LinkContainer>
                    <LogoutBtn />
                  </NavDropdown>
                ) : (
                  <LinkContainer to="/login">
                    <Nav.Link href="/login" className="align-center-gap0_5">
                      <FaUser /> {t("SignIn")}
                    </Nav.Link>
                  </LinkContainer>
                )}
                {userInfo && userInfo.isAdmin && (
                  <NavDropdown
                    title="Admin"
                    id="adminmenu"
                    className="align-center-gap0_5"
                  >
                    <LinkContainer to="/admin/users">
                      <NavDropdown.Item>{t("Users")}</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/admin/services">
                      <NavDropdown.Item>{t("Services")}</NavDropdown.Item>
                    </LinkContainer>
                  </NavDropdown>
                )}
                <Nav.Link className="align-center-gap0_5 px-0">
                  <LanguageSwitcher />
                </Nav.Link>
              </Col>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};
