import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Button, Table, Row } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { FaEdit, FaTrash } from "react-icons/fa";
import { MdOutlineBlock, MdClear } from "react-icons/md";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import {
  useGetStosByAdminQuery,
  useDeleteStoByAdminMutation,
} from "../../redux";
import { Loader, Message, Paginate, SearchBox } from "../../components";

export const ItemsAdminPage = () => {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const page = searchParams.get("page");
  const keyword = searchParams.get("keyword");
  const { t } = useTranslation();
  const { data, isLoading, error, refetch } = useGetStosByAdminQuery({
    keyword,
    page,
  });
  const [deleteSto, { isDelLoading }] = useDeleteStoByAdminMutation();

  const deleteHandler = async (stoId) => {
    if (window.confirm(`${t("AreYouSure")}`)) {
      try {
        await deleteSto(stoId).unwrap();
        toast.success(`${t("StoDeleted")}`);
        refetch();
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
    }
  };

  return (
    <>
      {keyword && (
        <Link to={location.pathname} className="btn btn-light mb-4">
          <MdClear /> {t("Filter")}
        </Link>
      )}
      <Row
        className="align-items-center d-flex justify-content-between flex-wrap flex-md-nowrap"
        style={{ gap: "20%" }}
      >
        <h1 className="w-25">{t("Services")}</h1>
        <Row className="p-0 w-auto flex-grow-1">
          <SearchBox />
        </Row>
      </Row>

      {isDelLoading && <Loader />}
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message></Message>
      ) : (
        <>
          <Table striped hover responsive className="table-sm">
            <thead>
              <tr>
                <th>ID</th>
                <th>{t("USER")}</th>
                <th>{t("NAME")}</th>
                <th>{t("ContactMail")}</th>
                <th>{t("IMAGE")}</th>
                <th>{t("Blocked")}</th>
              </tr>
            </thead>
            <tbody>
              {data.stos.map((item) => (
                <tr key={item._id}>
                  <td>{item._id}</td>
                  <td>{item.user}</td>
                  <td>{item.name}</td>
                  <td>{item.contactMail}</td>
                  <td>{item.image}</td>
                  <td>
                    {item.isBlocked ? (
                      <div style={{ color: "red" }}>
                        <MdOutlineBlock />
                      </div>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td>
                    <LinkContainer to={`${item._id}/edit`}>
                      <Button variant="dark" className="btn-sm">
                        <FaEdit style={{ color: "white" }} />
                      </Button>
                    </LinkContainer>
                    <Button
                      variant="danger"
                      className="btn-sm mx-2"
                      onClick={() => deleteHandler(item._id)}
                    >
                      <FaTrash style={{ color: "white" }} />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Paginate
            items={"stos"}
            pages={data.pages}
            page={data.page}
            isAdmin={true}
          />
        </>
      )}
    </>
  );
};
