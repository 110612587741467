import { Helmet } from "react-helmet-async";

export const Meta = ({
  title = "Welcome to AmMechanic",
  description = "We provide best car services platform",
  keywords = "cars, repair car, service, car service, mechanic, mechanics",
}) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
    </Helmet>
  );
};
