import { apiSlice } from "./api.slice.js";
import { ITEMS_URL } from "../../../constants/index.js";

export const itemSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getItems: builder.query({
      query: ({ filter, keyword, page, sort, onthego, open, near }) => {
        const params = {};

        if (!!filter && filter != null && filter !== "null")
          params.filter = filter;
        if (!!keyword && keyword != null && keyword !== "null")
          params.keyword = keyword;
        if (!!page && page != null && page !== "null") params.page = page;
        if (!!sort && sort != null && sort !== "null") params.sort = sort;
        if (!!onthego && onthego != null && onthego !== "null")
          params.onthego = onthego;
        if (!!open && open === "true") {
          params.open = open;
          params.userDateTime = new Date().toISOString();
        }
        if (!!near) params.near = near;

        return {
          url: ITEMS_URL,
          params,
        };
      },
      providesTags: ["Item"],
      keepUnusedDataFor: 5,
    }),
    getItemsLocations: builder.query({
      query: () => ({
        url: `${ITEMS_URL}/locations`,
      }),
      providesTags: ["Item"],
      keepUnusedDataFor: 5,
    }),
    getStoDetails: builder.query({
      query: (stoId) => ({
        url: `${ITEMS_URL}/${stoId}`,
      }),
      keepUnusedDataFor: 5,
    }),
    addStoOrderByUser: builder.mutation({
      query: (data) => ({
        url: `${ITEMS_URL}/users/sto-order`,
        method: "POST",
        body: data,
      }),
    }),
    updateStoOrderByUser: builder.mutation({
      query: (data) => ({
        url: `${ITEMS_URL}/users/sto-order`,
        method: "PUT",
        body: data,
      }),
    }),
    updateStoByAdmin: builder.mutation({
      query: ({ stoId, ...data }) => ({
        url: `${ITEMS_URL}/admin/${stoId}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Item"],
    }),
    deleteSto: builder.mutation({
      query: (stoId) => ({
        url: `${ITEMS_URL}/users/${stoId}`,
        method: "DELETE",
      }),
    }),
    payForStoAdding: builder.mutation({
      query: ({ stoId, details }) => ({
        url: `${ITEMS_URL}/users/${stoId}/pay`,
        method: "PUT",
        body: { ...details },
      }),
    }),
    getStosByAdmin: builder.query({
      query: ({ keyword, page }) => {
        const params = {};

        if (!!keyword && keyword != null && keyword !== "null")
          params.keyword = keyword;
        if (!!page && page != null && page !== "null") params.page = page;

        return {
          url: `${ITEMS_URL}/admin`,
          params,
        };
      },
      providesTags: ["Item"],
      keepUnusedDataFor: 5,
    }),
    deleteStoByAdmin: builder.mutation({
      query: (stoId) => ({
        url: `${ITEMS_URL}/admin/${stoId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Item"],
    }),
    updateStoBlockStatus: builder.mutation({
      query: (stoId) => ({
        url: `${ITEMS_URL}/admin/${stoId}/block`,
        method: "PUT",
      }),
      invalidatesTags: ["Item"],
    }),
    createReview: builder.mutation({
      query: (data) => ({
        url: `${ITEMS_URL}/${data.stoId}/reviews`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Item"],
    }),
    getTopStos: builder.query({
      query: () => ({
        url: `${ITEMS_URL}/top`,
      }),
      keepUnusedDataFor: 5,
    }),
    registerMechanic: builder.mutation({
      query: (data) => ({
        url: `${ITEMS_URL}/users`,
        method: "POST",
        body: data,
      }),
    }),
  }),
});
export const {
  useGetStoDetailsQuery,
  usePayForStoAddingMutation,
  useDeleteStoMutation,
  useAddStoOrderByUserMutation,
  useUpdateStoOrderByUserMutation,
  useUpdateStoByAdminMutation,
  useDeleteStoByAdminMutation,
  useUpdateStoBlockStatusMutation,
  useGetStosByAdminQuery,
  useCreateReviewMutation,
  useGetTopStosQuery,
  useGetItemsQuery,
  useGetItemsLocationsQuery,
  useRegisterMechanicMutation,
} = itemSlice;
