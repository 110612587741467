import { Pagination } from "react-bootstrap";
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";

export const Paginate = ({ pages, page }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const handlePageChange = (newFilter) => {
    searchParams.set("page", newFilter);

    navigate({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };
  return (
    pages > 1 && (
      <Pagination className="d-flex justify-content-center mt-3">
        {[...Array(pages).keys()].map((x) => (
          <Pagination.Item
            key={x}
            active={x + 1 === page}
            onClick={() => handlePageChange(x + 1)}
            className="p-1"
          >
            {x + 1}
          </Pagination.Item>
        ))}
      </Pagination>
    )
  );
};
