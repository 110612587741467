import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import L from "leaflet";
import marker from "../assets/location.png";
import userMarker from "../assets/userMarker.png";
import {
  Form,
  Col,
  Button,
  Alert,
  Row,
  OverlayTrigger,
  Tooltip,
  Image,
} from "react-bootstrap";
import { useGetItemsLocationsQuery } from "../redux";
import { Loader, Rating } from "../components";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { RiTeamFill } from "react-icons/ri";
import { IoMdPerson } from "react-icons/io";
import { FaMailBulk, FaPhone } from "react-icons/fa";

export const LocationsOnMap = () => {
  const { data: services, isLoading } = useGetItemsLocationsQuery();
  const [userLocation, setUserLocation] = useState(null);
  const [geoError, setGeoError] = useState(null);
  const { t } = useTranslation();

  const myIcon = new L.Icon({
    iconUrl: marker,
    iconRetinaUrl: marker,
    popupAnchor: [-0, -0],
    iconSize: [34, 45],
  });

  const userLocationIcon = new L.Icon({
    iconUrl: userMarker,
    iconRetinaUrl: userMarker,
    popupAnchor: [-0, -0],
    iconSize: [34, 45],
  });

  const filteredLocations =
    services?.services?.filter(
      (service) =>
        service.coordinates &&
        service.coordinates.lat !== undefined &&
        service.coordinates.lng !== undefined
    ) || [];

  const center = filteredLocations.length
    ? [
        filteredLocations.reduce((sum, loc) => sum + loc.coordinates.lat, 0) /
          filteredLocations.length,
        filteredLocations.reduce((sum, loc) => sum + loc.coordinates.lng, 0) /
          filteredLocations.length,
      ]
    : [49.2331, 28.4682];

  // Component to handle user's location
  const UserLocationButton = () => {
    const map = useMap();

    const handleShowLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setUserLocation([latitude, longitude]);
            map.flyTo([latitude, longitude], 13);
            setGeoError(null); // Reset any previous errors
          },
          (error) => {
            if (error.code === error.PERMISSION_DENIED) {
              setGeoError(t("GeolocationPermissionDenied"));
            } else {
              setGeoError(t("ErrorGettingLocation"));
            }
          }
        );
      } else {
        alert("Geolocation is not supported by this browser.");
      }
    };

    return (
      <>
        <Button
          onClick={handleShowLocation}
          variant="primary"
          className="position-absolute"
          style={{ top: 10, right: 10, zIndex: 1000 }}
        >
          {t("ShowMyLocation")}
        </Button>
        {geoError && (
          <Alert
            variant="danger"
            className="position-absolute"
            style={{ top: 60, right: 10, zIndex: 1000 }}
          >
            {geoError}
            <br />
            <Button
              onClick={handleShowLocation}
              variant="secondary"
              className="mt-2"
            >
              Try Again
            </Button>
          </Alert>
        )}
      </>
    );
  };

  return (
    <div className="mt-3 position-relative">
      <Form.Label style={{ fontSize: "24px", fontWeight: "500" }}>
        {t("CarServicesLocations")}
      </Form.Label>
      <Row className="my-3">
        <p className="p-0">{t("ViewLocationOfServices")}</p>
      </Row>
      {isLoading && <Loader />}
      <MapContainer center={center} zoom={6} style={{ height: "400px" }}>
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        {filteredLocations.map((service) => (
          <Marker
            key={service._id}
            position={[service.coordinates.lat, service.coordinates.lng]}
            icon={myIcon}
          >
            <Popup>
              <Col className="d-flex flex-column justify-content-center">
                <Row>
                  <Col className="m-0 p-0 fw-bold fs-6">{service.name}</Col>
                  <Col className="d-flex justify-content-end">
                    {service.role === "sto" ? (
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="tooltip-top">{t("ThisIsCSS")}</Tooltip>
                        }
                      >
                        <RiTeamFill style={{ width: "min-content" }} />
                      </OverlayTrigger>
                    ) : (
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="tooltip-top">
                            {t("ThisIndependentMaster")}
                          </Tooltip>
                        }
                      >
                        <IoMdPerson style={{ width: "min-content" }} />
                      </OverlayTrigger>
                    )}
                  </Col>
                </Row>
                <Image
                  src={service.image}
                  style={{ objectFit: "cover", height: "50px" }}
                />
                <Col className="d-flex flex-column align-items-center">
                  <p>
                    <Rating
                      value={service.rating.avg}
                      text={`${service.numReviews} reviews`}
                    />
                  </p>
                  <Col
                    onClick={() =>
                      window.open(
                        `https://mail.google.com/mail/?view=cm&fs=1&to=${service.contactMail}`,
                        "_blank"
                      )
                    }
                    className="btn-mail btn-link p-0"
                  >
                    <span className="p-1 fs-5">
                      <FaMailBulk />
                    </span>
                    {service.contactMail}
                  </Col>
                  {!!service.contactPhone1 && (
                    <Col
                      className="btn-link p-0"
                      onClick={() =>
                        window.open(`tel:${service.contactPhone1}`, "_self")
                      }
                    >
                      <span className="p-1 fs-6">
                        <FaPhone />
                      </span>
                      {service.contactPhone1}
                    </Col>
                  )}
                </Col>
                <Link
                  to={`/service/${service._id}`}
                  className="btn btn-primary mt-2 p-0 "
                  style={{
                    border: "1px solid gray",
                    height: "min-content",
                    color: "white",
                  }}
                >
                  {t("MoreInfo")}
                </Link>
              </Col>
            </Popup>
          </Marker>
        ))}
        {userLocation && (
          <Marker position={userLocation} icon={userLocationIcon}>
            <Popup>{t("YourLocation")}</Popup>
          </Marker>
        )}
        <UserLocationButton />
      </MapContainer>
    </div>
  );
};
