import { Dropdown, DropdownButton, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSearchParams, useNavigate } from "react-router-dom";
import { FaStarHalfAlt } from "react-icons/fa";
import { TfiMoney } from "react-icons/tfi";
import { IoMdArrowUp, IoMdArrowDown, IoMdTime } from "react-icons/io";

export const SortSelect = ({ sort }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { t } = useTranslation();

  const delParams = () => {
    if (sort) {
      searchParams.delete("near");
      searchParams.delete("page");
      navigate({
        pathname: "/services",
        search: searchParams.toString(),
      });
    }
  };

  const handleSortChange = (newSort) => {
    if (sort === newSort) {
      delParams();
    }
    searchParams.delete("page");
    searchParams.set("sort", newSort);

    navigate({
      pathname: "/services",
      search: searchParams.toString(),
    });
  };

  return (
    <Col>
      <DropdownButton
        title={t("Sort")}
        onSelect={handleSortChange}
        className={
          !sort
            ? "transparent-dropdown-button btn"
            : "transparent-dropdown-button btn btn-primary"
        }
        style={{
          borderRadius: "20px",
          border: "1px solid",
          padding: "0 12px",
        }}
      >
        <Dropdown.Item eventKey="" active={false}>
          {t("Sort")}
        </Dropdown.Item>
        <Dropdown.Item eventKey="rating.desc" active={sort === "rating.desc"}>
          <FaStarHalfAlt className="fs-5-6" />
          <IoMdArrowDown className="fs-5-6" />
          {t("RatingDesc")}
        </Dropdown.Item>
        <Dropdown.Item eventKey="rating.asc" active={sort === t("RatingAsc")}>
          <FaStarHalfAlt className="fs-5" /> <IoMdArrowUp className="fs-5-6" />
          {t("RatingAsc")}
        </Dropdown.Item>
        <Dropdown.Item eventKey="price.desc" active={sort === t("PriceDesc")}>
          <TfiMoney className="m-1 fs-5-6" />
          <IoMdArrowDown className="fs-5-6" />
          {t("PriceDesc")}
        </Dropdown.Item>
        <Dropdown.Item eventKey="price.asc" active={sort === t("PriceAsc")}>
          <TfiMoney className="m-1 fs-5-6" />
          <IoMdArrowUp className="fs-5-6" />
          {t("PriceAsc")}
        </Dropdown.Item>
        <Dropdown.Item
          eventKey="quality.desc"
          active={sort === t("QualityDesc")}
        >
          <span className="m-1 fs-5-6">👍</span>
          <IoMdArrowDown className="fs-5-6" />
          {t("QualityDesc")}
        </Dropdown.Item>
        <Dropdown.Item eventKey="quality.asc" active={sort === t("QualityAsc")}>
          <span className="m-1 fs-5-6">👍</span>
          <IoMdArrowUp className="fs-5-6" />
          {t("QualityAsc")}
        </Dropdown.Item>
        <Dropdown.Item eventKey="speed.desc" active={sort === t("SpeedDesc")}>
          <IoMdTime className="m-1 fs-5-6" />
          <IoMdArrowDown className="fs-5-6" />
          {t("SpeedDesc")}
        </Dropdown.Item>
        <Dropdown.Item eventKey="speed.asc" active={sort === t("SpeedAsc")}>
          <IoMdTime className="m-1 fs-5-6" />
          <IoMdArrowUp className="fs-5-6" />
          {t("SpeedAsc")}
        </Dropdown.Item>
      </DropdownButton>
    </Col>
  );
};
