import { Row, Col, Form, Alert, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import css from "./Timetable.module.css";

export const Timetable = ({ isUpdatable, timetable, setTimetable }) => {
  const { t } = useTranslation();
  const [validationError, setValidationError] = useState("");

  const handleTimeChange = (day, type, time) => {
    setTimetable((prevTimes) => ({
      ...prevTimes,
      [day]: {
        ...prevTimes[day],
        [type]: time,
      },
    }));
    if (type === "closing") {
      if (time <= timetable[day].opening) {
        setValidationError(
          `${t(day.charAt(0).toUpperCase() + day.slice(1))} ${t(
            "ClosingTimeMustBeAfterOpeningTime"
          )}`
        );
      } else {
        setValidationError("");
      }
    }
  };

  const handleCheckboxChange = (day, isChecked) => {
    setTimetable((prevState) => ({
      ...prevState,
      [day]: {
        ...prevState[day],
        closed: isChecked ? true : false,
      },
    }));
  };

  return (
    <Container className="mb-3 p-0">
      {validationError && <Alert variant="danger">{validationError}</Alert>}
      <Row style={{ display: "flex", gap: "1rem" }}>
        {Object.keys(timetable).map((day) => {
          const isClosed = timetable[day].closed;
          return (
            <Col key={day} className={css.dayCard}>
              <Form.Group controlId={`${day}Time`}>
                <Form.Label style={{ color: "#5c6670" }}>
                  {t(day.charAt(0).toUpperCase() + day.slice(1))}
                </Form.Label>
                <Row>
                  <div>
                    <div
                      style={
                        isUpdatable || isClosed
                          ? { backgroundColor: "white", color: "lightgray" }
                          : {}
                      }
                    >
                      <Col>
                        <Form.Label>{t("OpeningTime")}</Form.Label>
                        <Form.Control
                          type="time"
                          required
                          value={timetable[day].opening}
                          disabled={isUpdatable || isClosed}
                          style={
                            isClosed || isUpdatable
                              ? { backgroundColor: "white", color: "lightgray" }
                              : {}
                          }
                          onChange={(e) =>
                            handleTimeChange(day, "opening", e.target.value)
                          }
                        />
                      </Col>
                      <Col>
                        <Form.Group controlId="closingTime" className="my-3">
                          <Form.Label>{t("ClosingTime")}</Form.Label>
                          <Form.Control
                            type="time"
                            value={timetable[day].closing}
                            disabled={isUpdatable || isClosed}
                            style={
                              isClosed || isUpdatable
                                ? {
                                    backgroundColor: "white",
                                    color: "lightgray",
                                  }
                                : {}
                            }
                            onChange={(e) =>
                              handleTimeChange(day, "closing", e.target.value)
                            }
                          />
                        </Form.Group>
                      </Col>
                    </div>
                    <Form.Check
                      type="checkbox"
                      id="closedCheckbox"
                      label={t("Closed")}
                      disabled={isUpdatable}
                      checked={isClosed}
                      onChange={(e) =>
                        handleCheckboxChange(day, e.target.checked)
                      }
                    />
                  </div>
                </Row>
              </Form.Group>
            </Col>
          );
        })}
      </Row>
    </Container>
  );
};
