import React from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import "./language/i18n";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { Provider } from "react-redux";
import { store } from "./redux";
import { HelmetProvider } from "react-helmet-async";
import "./assets/styles/bootstrap.custom.css";
import "./assets/styles/index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
  PrivateRoute,
  AdminRoute,
  MechanicRoute,
  RegisteredNonMechanicRoute,
  UserStosRoute,
} from "./components";
import {
  ItemsPage,
  ItemPage,
  CartPage,
  LoginPage,
  RegisterPage,
  ProfilePage,
  MechanicRegisterPage,
  MechanicProfilePage,
  ItemsAdminPage,
  UsersAdminPage,
  StoRegisterPage,
  UserStosPage,
  PlaceOrderPage,
  NotFoundPage,
  ItemEditPage,
  UserEditPage,
  HomePage,
  ConfirmEmailPage,
} from "./pages";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route index element={<Navigate to="/home" replace />} />
      <Route path="/services" element={<ItemsPage />} />
      <Route path="/home" element={<HomePage />} />
      <Route path="/confirm-email" element={<ConfirmEmailPage />} />

      <Route path="/service/:id" element={<ItemPage />} />

      <Route path="/cart" element={<CartPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/register" element={<RegisterPage />} />

      <Route path="" element={<PrivateRoute />}>
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/addservice" element={<StoRegisterPage />} />
        <Route path="/placeorder" element={<PlaceOrderPage />} />

        <Route path="" element={<RegisteredNonMechanicRoute />}>
          <Route path="/mechanicregister" element={<MechanicRegisterPage />} />
        </Route>

        <Route path="" element={<MechanicRoute />}>
          <Route path="/mechanic-profile" element={<MechanicProfilePage />} />
        </Route>

        <Route path="" element={<UserStosRoute />}>
          <Route path="/mystos" element={<UserStosPage />} />
        </Route>
      </Route>

      <Route path="/admin" element={<AdminRoute />}>
        <Route path="users" element={<UsersAdminPage />} />
        <Route path="users/page/:pageNumber" element={<UsersAdminPage />} />
        <Route path="users/search/:keyword" element={<UsersAdminPage />} />
        <Route path="users/:userId/edit" element={<UserEditPage />} />
        <Route path="services" element={<ItemsAdminPage />} />
        <Route path="services/:serviceId/edit" element={<ItemEditPage />} />
      </Route>
      <Route path="*" element={<NotFoundPage />} />
    </Route>
  )
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <Provider store={store}>
        <PayPalScriptProvider deferLoading={true}>
          <RouterProvider router={router} />
        </PayPalScriptProvider>
      </Provider>
    </HelmetProvider>
  </React.StrictMode>
);

reportWebVitals();
