import { toast } from "react-toastify";

export const imageCheckAndUpload = async ({
  image: file,
  setImage,
  setImageError,
  uploadImage,
}) => {
  if (!!file) {
    if (file?.size < 1024 * 1024) {
      setImageError(null);
      const reader = new FileReader();

      const readFile = () => {
        return new Promise((resolve, reject) => {
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(file);
        });
      };

      try {
        const imageData = await readFile();
        const formData = new FormData();
        formData.append("image", file);

        const { image: imagePath } = await uploadImage(formData).unwrap();
        setImage(imageData);
        return imagePath;
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
    } else {
      setImage(null);
      setImageError("Image size should be less than 10MB");
    }
  } else {
    setImage(null);
    setImageError(null);
  }
};
