import { Image, Row, Col, Card, Button } from "react-bootstrap";
import { FeedbackForm } from "../components";

import { useTranslation } from "react-i18next";
import mainImage from "../assets/IMG_20240805_140246_734.jpg";
import image1 from "../assets/images.jpeg";
import image2 from "../assets/MechanicShopNearMeVista.jpg";
import image3 from "../assets/group-four-car-service-technician-600nw-2048001587.webp";
import { LocationsOnMap } from "../components";

export const HomePage = () => {
  const { t } = useTranslation();

  return (
    <Row>
      <Image src={mainImage} />

      <Row className="mt-3">
        <Col>
          <h1 className="text-center"> {t("WelcomeToCarRepairCommunity")}</h1>
          <p className="text-center">{t("YourOneStopSolution")}</p>
        </Col>
      </Row>

      <Row className="my-3">
        <h5 className="text-center mb-2">{t("AboutOurSite")}</h5>
        <p>{t("OurSiteIsDesigned")}</p>
      </Row>

      <LocationsOnMap />

      <Row className="my-3">
        <p className="p-0">{t("OurMission")}</p>
      </Row>

      <Row className="my-3 row-gap-4 ">
        <Col md={6} lg={4}>
          <Card>
            <Card.Img variant="top" src={image1} alt="Car Station" />
            <Card.Body>
              <Card.Title>
                <h5>{t("FindCarStation")}</h5>
              </Card.Title>
              <Card.Text>{t("DiscoverTheBestCSS")}</Card.Text>
              <Button variant="primary" href="/stos">
                {t("FindStations")}
              </Button>
            </Card.Body>
          </Card>
        </Col>

        <Col md={6} lg={4}>
          <Card>
            <Card.Img variant="top" src={image2} alt="Mechanic" />
            <Card.Body>
              <Card.Title>
                <h5> {t("FindMechanic")}</h5>
              </Card.Title>
              <Card.Text>{t("ConnectExperiencedMechanics")}</Card.Text>
              <Button variant="primary" href="/mechanics">
                {t("SeekOutMechanic")}
              </Button>
            </Card.Body>
          </Card>
        </Col>

        <Col md={6} lg={4}>
          <Card>
            <Card.Img variant="top" src={image3} alt="Community" />
            <Card.Body>
              <Card.Title>
                <h5>{t("BecomeMechanic")}</h5>
              </Card.Title>
              <Card.Text>{t("BecomePartOfCommunity")}</Card.Text>
              <Button variant="primary" href="/mechanicregister">
                {t("JoinTheTeam")}
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <FeedbackForm />
    </Row>
  );
};
