// const BASE_URL =
//   process.env.NODE_ENV === "development" ? "http://localhost:8000" : "";

const BASE_URL = "";

const ITEMS_URL = "/api/items";
const USERS_URL = "/api/users";
const MECHANICS_URL = "/api/mechanics";
const PAYPAL_URL = "/api/etc/paypal";
const FEEDBACK_URL = "/api/etc/feedback";
const UPLOADS_URL = "/api/uploads";

export {
  BASE_URL,
  USERS_URL,
  MECHANICS_URL,
  PAYPAL_URL,
  FEEDBACK_URL,
  UPLOADS_URL,
  ITEMS_URL,
};
