import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Button, InputGroup } from "react-bootstrap";
import {
  useParams,
  useSearchParams,
  useNavigate,
  useLocation,
} from "react-router-dom";

const SearchBox = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { keyword: urlKeyword } = useParams();
  const [keyword, setKeyword] = useState(urlKeyword || "");
  const [searchParams] = useSearchParams();
  const submitHandler = (e) => {
    e.preventDefault();

    if (keyword.trim()) {
      setKeyword("");
      searchParams.set("keyword", keyword);
      navigate({
        pathname: location.pathname,
        search: searchParams.toString(),
      });
    }
  };

  return (
    <Form onSubmit={submitHandler} className="d-flex align-items-center">
      <InputGroup className="flex-nowrap">
        <Form.Control
          type="text"
          name="q"
          onChange={(e) => setKeyword(e.target.value)}
          value={keyword}
          placeholder={t("SearchCSS")}
          style={{
            width: "125px",
            height: "30px",
            borderRadius: "25px 0 0 25px",
            fontSize: "0.9rem",
            padding: "0.3rem 0.8rem",
          }}
        />
        <Button
          type="submit"
          variant="outline-light"
          style={{
            height: "30px",
            borderTopRightRadius: "25px",
            borderBottomRightRadius: "25px",
            fontSize: "0.9rem",
            padding: "0.3rem 0.8rem",
            borderColor: "var(--bs-nav-link-color)",
            color: "var(--bs-nav-link-color)",
          }}
        >
          {t("Search")}
        </Button>
      </InputGroup>
    </Form>
  );
};

export { SearchBox };
