import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Row, Col, ListGroup, Image, Button } from "react-bootstrap";
import { FaTrash, FaMapMarkedAlt } from "react-icons/fa";
import { Message } from "../components";
import { useDeleteStoMutation } from "../redux";
import { toast } from "react-toastify";
import { setUserStos } from "../redux";
import { useDispatch } from "react-redux";

export const UserStosPage = () => {
  const { t } = useTranslation();
  const { userStos } = useSelector((state) => state.item);
  const [deleteSto, { isLoading }] = useDeleteStoMutation();

  const dispatch = useDispatch();

  const deleteHandler = async (e, stoId) => {
    e.preventDefault();
    if (window.confirm(`${t("AreYouSure")}`)) {
      try {
        const { userStos } = await deleteSto(stoId).unwrap();
        if (!!userStos && userStos.length !== 0) {
          dispatch(setUserStos(userStos));
        }
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
    }
  };

  return (
    <Col md={8} className="d-flex flex-column m-auto">
      <h2>{t("mycss")}</h2>
      {userStos.length === 0 ? (
        <Message>
          {t("noCreatedCss")} <Link to="/">{t("goBack")}</Link>
        </Message>
      ) : (
        <ListGroup variant="flush">
          <>
            {userStos.map((item) => (
              <ListGroup.Item key={item._id}>
                <Row>
                  <Col
                    md={2}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Image src={item.image} alt={item.name} fluid rounded />
                  </Col>
                  <Col md={3}>
                    <Link to={`/service/${item._id}`}>{item.name}</Link>
                  </Col>

                  <Col
                    md={4}
                    onClick={() =>
                      window.open(
                        `https://www.google.com/maps/dir//${item.coordinates.lat},${item.coordinates.lng}`,
                        "_blank"
                      )
                    }
                  >
                    <span className="p-1 fs-3">
                      <FaMapMarkedAlt />
                    </span>
                    {item.address}
                    <Row style={{ fontSize: "10px", marginLeft: "0.3rem" }}>
                      ({t("clickToOpenOnMap")})
                    </Row>
                  </Col>
                  <Col md={1}>
                    <Button
                      type="button"
                      variant="light"
                      disabled={isLoading}
                      onClick={(e) => deleteHandler(e, item._id)}
                    >
                      <FaTrash />
                    </Button>
                  </Col>
                </Row>
              </ListGroup.Item>
            ))}
          </>
        </ListGroup>
      )}
    </Col>
  );
};
